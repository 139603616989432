import React from "react";
import { useLanguageForShared } from "../../../../../shared/utils/languages/useLanguageForShared";
import LabelValueCopy from "../../../../../shared/custom/labelValue/LabelValueCopy";
import PatientRowInfo from "./PatientRowInfo";
import { customerTypeName } from "../../../../../redux/customer/customerData";
import AlertRegistry from "../alert/AlertRegistry";
import { useSelector } from "react-redux";
import { selectCustomerType } from "../../../../modules/Auth/_redux/authRedux";
import Tag from "../../../../../shared/custom/tag/Tag";
import configuration from "../../../../../shared/configuration";

const DetailPatientRegistryMore = ({ patient, order }: { patient: any, order?: any }) => {
  const { t } = useLanguageForShared();
  const customerTypeSel = useSelector(selectCustomerType);

  const residence = `${patient?.residence?.street ? patient?.residence?.street : "-"}${patient?.residence?.number ? (", " + patient?.residence?.number) : ""} ${patient?.residence?.city || ""} ${patient?.residence?.province || ""} ${patient?.residence?.cap || ""}`;
  const domicile = `${patient?.domicile?.street ? patient?.domicile.street : "-"}${patient?.domicile?.number ? (", " + patient?.domicile?.number) : ""} ${patient?.domicile?.city || ""} ${patient?.domicile?.province || ""} ${patient?.domicile?.cap || ""}`;

  return (
    <div className={"detail-patient"}>
      {customerTypeSel === customerTypeName.bo && order?.patientHasPendingChanges && order?.dettaglioOrdine?.patient?.hasPendingChanges &&
        <div className={"d-flex justify-content-between"}>
          <AlertRegistry />
        </div>
      } {/*LOGICA check se utente BackOffice e se il paziente è NAV e ha l'indirizzo variato rispetto a quello salvato*/}
      <div className={"d-flex gap-4 align-items-baseline"}>
        {order?.patientSource !== "lsi" && <div>
          <Tag
            bgColor={configuration.theme.gray100}
            color={configuration.theme.primaryDark}
            label={t("sg.newPatient")}
          />
        </div>}
        <PatientRowInfo patient={patient} />
      </div>


      <div className={"d-flex flex-wrap gap-5 info-col"}>
        <LabelValueCopy label={t("registry.telephone")} value={patient?.phone || "-"} labelClass={"text-sm"} />
        <LabelValueCopy label={t("registry.mobile")} value={patient?.mobile || "-"} labelClass={"text-sm"} />
        <LabelValueCopy label={t("registry.email")} value={patient?.email || "-"} labelClass={"text-sm"} />
        <LabelValueCopy label={t("registry.resident")} value={residence} labelClass={"text-sm"} />
        <LabelValueCopy label={t("registry.domicileIn")} value={domicile} labelClass={"text-sm"} />
      </div>
    </div>
  );
};

export default DetailPatientRegistryMore;
