import {testSNOMED} from "./testData";
import {usePathologiesTherapyData} from "./usePathologiesTherapyData";

export const useSleepStudyData = (t: any, dataForm: any) => {

    const {pathologiesSNOMED} = usePathologiesTherapyData(t, dataForm)

    const formStructureTest = [
        {
            name: "test",
            type: "select",
            label: `${t("prescription.template.test.label")}*`,
            options: Object.keys(testSNOMED).map(ele => ({
                    label: testSNOMED[ele] ? t(`prescription.template.test.${testSNOMED[ele]}`) : '-',
                    value: ele
                })
            ),
            dataElement: {
                type: "text"
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"}
        },
        /*{
            name: "suspectedPathologies",
            type: "select",
            label: `${t("common.pathologies")}*`,
            options: Object.keys(pathologiesSNOMED).map(ele => ({
                    label: t(`pathology.${pathologiesSNOMED[ele]}`),
                    value: ele
                })
            ),
            dataElement: {
                type: "text",
                multi: true
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"}
        },*/
        /*{
          name: "suspectedPathologies",
          type: "select",
          label: `${t("prescription.template.suspectedPathologies.label")}*`,
          options: Object.keys(suspectedPathology).map(ele => ({
              label: t(`prescription.template.suspectedPathologies.${ele}`),
              value: ele
            })
          ),
          dataElement: {
            type: "text",
            multi: false
          },
          col: { xs: "6", sm: "4", lg: "3", xl: "3" }
        },*/
        {
            type: "input",
            label: t(`prescription.template.urgent`),
            col: {xs: "6", sm: "4", lg: "3", xl: "3"},
            name: "urgent",
            dataElement: {
                type: "checkbox"
            },
            display: {
                form: "inlineFormReverse mt-5 pt-5",
                label: "",
                child: ""
            }
        },
        /*{
            type: "input",
            label: t(`prescription.template.pediatric`),
            col: {xs: "6", sm: "4", lg: "3", xl: "3"},
            name: "pediatric",
            dataElement: {
                type: "checkbox"
            },
            display: {
                form: "inlineFormReverse mt-5 pt-5",
                label: "",
                child: ""
            }
        }*/
    ];
    const formStructureTestExecution = [
        {
            name: "testExecutionAddress",
            type: "input",
            label: `${t("prescription.template.testExecutionAddress.label")}*`,
            dataElement: {
                type: "text",
                placeholder: t('prescription.template.testExecutionAddress.placeholder'),
            },
            col: {xs: "6", sm: "6", lg: "6", xl: "6"}
        },
        {
            name: "clinicalCenter",
            type: "input",
            label: `${t("prescription.template.clinicalCenter.label")}*`,
            dataElement: {
                type: "text",
                placeholder: t('prescription.template.clinicalCenter.placeholder'),
            },
            col: {xs: "6", sm: "6", lg: "6", xl: "6"}
        },
    ];
    const formStructureTestRequest = [
        {
            name: "request",
            type: "input",
            label: `${t("prescription.template.request.label")}*`,
            dataElement: {
                type: "text",
                placeholder: t('prescription.template.request.placeholder'),
            },
            col: {xs: "6", sm: "4", lg: "3", xl: "3"}
        },
    ];
    return {
        formStructureTest, formStructureTestExecution, formStructureTestRequest
    };
};
