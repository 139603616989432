import {
  modeChoose, modeChooseTherapy,
  structureAPAP,
  structureAUTOST, structureBILEVELAUTO,
  structureBILEVELS,
  structureCPAP,
  structureST,
  structureTAPVCS
} from "../../../../../../../shared_lsi_sg/modeDataForm";

export const useModeDataTherapy = (t: any) => {
  const createElementForm = (ele: any) => {

    return {
      type: "input",
      label: t(`prescription.template.${ele.name}.label`) + (ele?.req ? "*" : ""),
      dataElement: {
        placeholder: t(`prescription.template.${ele.name}.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", md: "4", lg: "auto" },
      display: {
        form: "w-lg-150px label-no-wrap",
        label: "1",
        child: "2 "
      },
      ...ele
    };
  };

  const formStructureModeChoose: { [key: string]: any } = Object.keys(modeChooseTherapy).reduce((acc: any, mode) => {
    return { ...acc, [mode]: modeChooseTherapy[mode].map((ele: string) => createElementForm(ele)) };
  }, {});

  return {
    formStructureModeChoose
  };
};
