import React from "react";
import {Col, Row} from "react-bootstrap";
import {OrderDetailComponent} from "./OrderDetailComponent";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

export function SummaryOrder({
                                 orderDetail,
                                 order,
                                 resetSummary,
                                 createOrder
                             }) {
    const {t} = useLanguageForShared();
    return (
        <>
            <OrderDetailComponent order={order} orderDetail={orderDetail} noActions={true}/>
            <Row className="justify-content-end mt-3">
                <Col xs="auto">
                    <BtnSecondary data-test="cancel-order" onClick={() => createOrder(true)}>
                        {t("action.save_draft")}
                    </BtnSecondary>
                </Col>
                <Col xs="auto">
                    <BtnSecondary className="mr-3" onClick={resetSummary}>
                        {t("action.back")}
                    </BtnSecondary>
                </Col>
                <Col xs="auto">
                    <BtnPrimary data-test="order-send" onClick={() => createOrder(false)}>
                        {t("action.send")}
                    </BtnPrimary>
                </Col>
            </Row>
        </>
    );
}
