import {
  StructureDetailHumidifier,
  StructureDetailInterface, StructureDetailMask,
  StructureDetailRampa,
  structureHumidifier,
  structureInterface, structureMask, structureNote,
  structureRampa
} from "./paramsData";
import { maskMapped } from "./maskData";

export const useParamsTitrationData = (t: any, dataForm: any) => {
  const createElementForm = (ele: any) => {
    return {
      type: 'input',
      label: t(`prescription.template.${ele.name}.label`),
      dataElement: {
        placeholder: t(`prescription.template.${ele.name}.placeholder`),
        type: 'text',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
      ...ele
    }
  }

  const formStructureDetailRampa = [
    {
      name: "initialPress"
    },
    {
      name: "duration"
    },
    {
      name: "rampaType"
    }
  ].map(ele => createElementForm(ele));
  const formStructureDetailInterface = StructureDetailInterface.map(ele => createElementForm(ele));
  const formStructureDetailHumidifier = StructureDetailHumidifier.map(ele => createElementForm(ele));
  const formStructureDetailMask = [
    {
      name: "maskModel_1",
      type: 'select',
      label: t(`prescription.template.maskModel.label`) + ' 1',
      options: Object.keys(maskMapped).map(ele => ({
          label: maskMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.maskModel.placeholder`),
        type: 'text',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
    {
      name: "maskModel_2",
      type: 'select',
      label: t(`prescription.template.maskModel.label`) + ' 2',
      options: Object.keys(maskMapped).map(ele => ({
          label: maskMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.maskModel.placeholder`),
        type: 'text',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
    {
      name: "maskModel_3",
      type: 'select',
      label: t(`prescription.template.maskModel.label`) + ' 3',
      options: Object.keys(maskMapped).map(ele => ({
          label: maskMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.maskModel.placeholder`),
        type: 'text',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
    {
      name: "maskModel_4",
      type: 'select',
      label: t(`prescription.template.maskModel.label`) + ' 4',
      options: Object.keys(maskMapped).map(ele => ({
          label: maskMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.maskModel.placeholder`),
        type: 'text',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
  ]
  const formStructureDate = [
    {
      name: "startDate",
      type: 'input',
      label: t(`prescription.template.startDate.label`),
      dataElement: {
        placeholder: t(`prescription.template.startDate.placeholder`),
        type: 'date',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
    {
      name: "endDate",
      type: 'input',
      label: t(`prescription.template.endDate.label`),
      dataElement: {
        placeholder: t(`prescription.template.endDate.placeholder`),
        type: 'date',
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "2" },
    },
  ]


  const formStructureRampa = [
    ...structureRampa.map(ele => createElementForm(ele)),
    ...(dataForm?.rampa ? formStructureDetailRampa : [])
  ];


  const formStructureInterface = [
    ...structureInterface.map(ele => createElementForm(ele)),
    ...(dataForm?.interface ? formStructureDetailInterface : [])
  ];
  const formStructureHumidifier = [
    ...structureHumidifier.map(ele => createElementForm(ele)),
  ]
  const formStructureMask = [
    ...structureMask.map(ele => createElementForm(ele)),
    ...(dataForm?.mask ? formStructureDetailMask : [])
  ]
  const formStructureNote = [
    ...structureNote.map(ele => createElementForm(ele))
  ]

  return {
    formStructureRampa,
    formStructureInterface,
    formStructureHumidifier,
    formStructureNote,
    formStructureDetailRampa,
    formStructureDetailInterface,
    formStructureDetailHumidifier,
    formStructureMask,
    formStructureDate
  };
};
