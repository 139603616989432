// TTI-1394 just polysomnography in the select
export const testSNOMED = {
    "60554003": "polysomnography",
    "MS9867I": "respiratoryPolygraphy",
    // "29303009": "ecg",
    // "127783003": "spirometry",
    // "252465000": "pulseOximetry",
    // "264598005": "oximetry"
};


export const suspectedPathology = {
    "copd": "copd",
    "sleepApnea": "BPCO",
};