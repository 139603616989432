import { templateNameId, templateNameType } from "../../../../../../../shared_lsi_sg/templateData";
import { useValidationOxygen } from "./useValidationOxygen";
import { useValidationVentilo } from "./useValidationVentilo";
import { useValidationSleep } from "./useValidationSleep";
import { useValidationConcentrator } from "./useValidationConcentrator";
import { useValidationSleepStudy } from "./useValidationSleepStudy";
import { useValidationTherapy } from "./useValidationTherapy";
import { useValidationScoredDiagnosticStudy } from "./useValidationScoredDiagnosticStudy";
import { useValidationTitration } from "./useValidationTitration";


export const useValidationProduct = (template: string, t: any, dataForm: any) => {
  const templates = {
    [templateNameId[templateNameType.note]]: {},
    [templateNameId[templateNameType.ossigeno]]: useValidationOxygen(t, dataForm),
    [templateNameId[templateNameType.ventiloterapia]]: useValidationVentilo(t, dataForm),
    [templateNameId[templateNameType.sleep]]: useValidationSleep(t, dataForm),
    [templateNameId[templateNameType.concentrator]]: useValidationConcentrator(t, dataForm),
    [templateNameId[templateNameType.sleepStudy]]: useValidationSleepStudy(t, dataForm),
    [templateNameId[templateNameType.therapy]]: useValidationTherapy(t, dataForm),
    [templateNameId[templateNameType.scoredDiagnosticStudy]]: useValidationScoredDiagnosticStudy(t, dataForm),
    [templateNameId[templateNameType.titration]]: useValidationTitration(t, dataForm),
  }

  return {
    validations: template && templates[template] ? templates[template] : {}
  }

}
