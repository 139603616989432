import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";
import { maskMapped } from "../../app/pages/products/selectProduct/component/templates/data/maskData";
import { partsData } from "../../app/pages/products/selectProduct/component/templates/data/partsData";


export function TemplateGenericProductRead({ data }: any) {
  const { t } = useLanguageForShared();


  return (
    <div className={"template-sg ventilo-template row row-gap-4"}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
        <div className={"row row-gap-4"}>
          <div className="col-sm-12 col-md-4">
            {data?.modelDevice && <LabelValue
              label={t("prescription.template.therapy.model.label")}
              value={partsData[data?.model] || '-'} />}
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelValue label={t("prescription.template.therapy.quantity.label")}
                        value={data?.quantity || '-'} className={"me-5"} />
          </div>
        </div>
      </div>

      <div className={"row row-gap-4"}>
        <div className="col-sm-12 col-md-12">
          <LabelValue label={t("prescription.template.note.label")}
                      value={data?.note} />

        </div>
      </div>
    </div>
  );
}
