import React from "react";
import { templateNameId, templateNameType } from "../../../../../../shared_lsi_sg/templateData";
import { TemplateNote } from "./TemplateNote";
import { TemplateOxigen } from "./TemplateOxigen";
import { TemplateSleep } from "./TemplateSleep";
import VentiloTemplate from "./VentiloTemplate";
import { TemplateConcentrator } from "./TemplateConcentrator";
import { TemplateSleepStudy } from "./TemplateSleepStudy";
import { TemplateTherapy } from "./TemplateTherapy";
import { TemplateScoredDiagnosticStudy } from "./TemplateScoredDiagnosticStudy";
import { TemplateTitration } from "./TemplateTitration";
import { TemplateMaskProduct } from "./TemplateMaskProduct";
import { TemplateGenericProduct } from "./TemplateGenericProduct";
import { TemplateNivTherapy } from "./TemplateNivTherapy";

export function TemplateSelectProduct({ formik, dataForm, setData, template, changeValue, errors, isSubmit }) {
  const templateList = {
    [templateNameId[templateNameType.note]]: <TemplateNote isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.ossigeno]]: <TemplateOxigen isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.ventiloterapia]]: <VentiloTemplate dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} isSubmit={isSubmit} />,
    [templateNameId[templateNameType.sleep]]: <TemplateSleep isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.concentrator]]: <TemplateConcentrator isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.sleepStudy]]: <TemplateSleepStudy isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.therapy]]: <TemplateTherapy isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.scoredDiagnosticStudy]]: <TemplateScoredDiagnosticStudy isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.titration]]: <TemplateTitration isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.maskProduct]]: <TemplateMaskProduct isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.genericProduct]]: <TemplateGenericProduct isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />,
    [templateNameId[templateNameType.nivTherapy]]: <TemplateNivTherapy isSubmit={isSubmit} dataForm={dataForm} changeValue={changeValue} errors={errors} setData={setData} />
  };
  return (
    <>
      {templateList[template]}
    </>
  );
}
