import { devicesMapped } from "./deviceData";

export const useNivTherapyData = (t: any, dataForm: any) => {


  const formStructureDevice = [
    ...((dataForm?.mode === "autost" || dataForm?.mode === "apvc" || dataForm?.mode === "s") ? [{
      name: "guaranteedVolume",
      type: "input",
      label: t(`prescription.template.therapy.guaranteedVolume.label`),
      dataElement: {
        placeholder: t(`prescription.template.therapy.guaranteedVolume.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    }] : []),
    ...(dataForm?.mode === "st" ? [{
      name: "riseTime",
      type: "input",
      label: t(`prescription.template.riseTime.label`),
      dataElement: {
        placeholder: t(`prescription.template.riseTime.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    }] : []),
    ...(dataForm?.mode === "t" ? [{
      name: "iERatio",
      type: "input",
      label: t(`prescription.template.therapy.iERatio.label`),
      dataElement: {
        placeholder: t(`prescription.template.therapy.iERatio.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    }] : []),
    {
      name: "modelDevice",
      type: "select",
      label: t(`prescription.template.therapy.modelDevice.label`),
      options: Object.keys(devicesMapped).map(ele => ({
          label: devicesMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.therapy.modelDevice.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    },
  ];


  return {
    formStructureDevice
  };
};
