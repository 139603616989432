import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedProductIsInCart, selectSelectedProductWithInfo,} from "../../../../redux/products/productsSlice";

import {AddProductToCartAction} from "../../../../redux/cart/cartActions";
import {useRoute} from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {selectIsPharmacy} from "../../../../redux/customer/customerSlice";
import {
    setProductOrderDataActionPharmacy,
    setProductOrderDataActionPrescriber,
} from "../../../../redux/order/orderActions";
import {selectPatient} from "../../../../redux/patient/patientSlice";
import {resetEditOrderData, setFromCart} from "../../../../redux/order/orderSlice";
import {SelectProductComponent} from "./component/SelectProductComponent";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

export function SelectProductPage({history}) {
    const {t} = useLanguageForShared();
    const dispatch = useDispatch();
    const product = useSelector(selectSelectedProductWithInfo);
    const isInCart = useSelector(selectSelectedProductIsInCart);
    const isPharmacy = useSelector(selectIsPharmacy);
    const patient = useSelector(selectPatient);
    const [quantityValue, setQuantityValue] = useState(1);
    const changeQuantityProduct = (quantity) => {
        setQuantityValue(quantity);
    };
    const {changeRoute} = useRoute();
    const changeRouteProducts = () => {
        changeRoute(routeName.products);
    };
    useEffect(() => {
        dispatch(setFromCart(false));
        dispatch(resetEditOrderData());
    }, []);
    useEffect(() => {
        product
            ? setQuantityValue(
                product.specifiedQuantity ? product.quantity : undefined
            )
            : changeRouteProducts();
    }, [product]);

    const addToCart = (detail) => {
        dispatch(
            AddProductToCartAction(t, {...product, quantity: quantityValue, detail})
        );
        changeRoute(routeName.cart);
    };
    const createOrder = (detail) => {
        const changeR = () => changeRoute(routeName.orderPage);
        isPharmacy
            ? dispatch(
                setProductOrderDataActionPharmacy(t,
                    {...product, quantity: quantityValue, detail},
                    changeR
                )
            )
            : dispatch(
                setProductOrderDataActionPrescriber(t,
                    {
                        ...product,
                        quantity: quantityValue,
                        detail: {...detail, patient},
                    },
                    changeR
                )
            );
    };

    return (
        <SelectProductComponent
            isPharmacy={isPharmacy}
            isInCart={isInCart}
            createOrder={createOrder}
            changeQuantityProduct={changeQuantityProduct}
            changeRouteProducts={changeRouteProducts}
            product={product}
            patient={patient}
            addToCart={addToCart}
        />
    );
}
