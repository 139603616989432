import React from "react";
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useSleepStudyData} from "./data/useSleepStudyData";
import {useParamsData} from "./data/useParamsData";


export function TemplateSleepStudy({dataForm, changeValue, errors, setData, isSubmit}) {
    const {t} = useLanguageForShared()
    const {
        formStructureTest,
        formStructureTestExecution,
        formStructureTestRequest,
    } = useSleepStudyData(t, dataForm)
    const {formStructureNote} = useParamsData(t, dataForm)

    return (
        <>
            <div>
                <FormElements
                    isSubmit={isSubmit}
                    structure={formStructureTest}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
                <FormElements
                    isSubmit={isSubmit}
                    structure={formStructureTestExecution}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
                <FormElements
                    isSubmit={isSubmit}
                    structure={formStructureTestRequest}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
                <FormElements
                    structure={formStructureNote}
                    dataForm={dataForm}
                    changeValue={changeValue}
                    errors={errors}
                    isSubmit={isSubmit}
                />

            </div>
        </>
    );
}
