import React from "react";
import { useLanguageForShared } from "../../../../../../shared/utils/languages/useLanguageForShared";
import { FormElements } from "../../../../../../shared/bootstrap/form/FormElements";
import { useParamsData } from "./data/useParamsData";
import { useMaskProductData } from "./data/useMaskProductData";

export function TemplateMaskProduct({ dataForm, changeValue, errors, setData, isSubmit }) {
  const { t } = useLanguageForShared()
  console.log(dataForm)
  const { formStructureModel, formStructureQuantity } = useMaskProductData(t, dataForm);
  const {
    formStructureNote,
  } = useParamsData(t, dataForm)


  return (
    <>
      <div className={'d-flex'}>
        <div className={'w-100'}>
          <FormElements
            isSubmit={isSubmit}
            structure={formStructureModel}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValue} />
        </div>
        <div className={'w-100'}>
          <FormElements
            isSubmit={isSubmit}
            structure={formStructureQuantity}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValue} />
        </div>
      </div>
      <FormElements
        structure={formStructureNote}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </>
  );
}
