import React from "react";
import {Col, Row} from "react-bootstrap";
import BtnPrimary from "../../../../../shared/bootstrap/button/primary/BtnPrimary";
import BtnSecondary from "../../../../../shared/bootstrap/button/secondary/BtnSecondary";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";

export function SelectProductButtons({
                                         changeRouteProducts,
                                         addToCart,
                                         createOrder,
                                         isInCart,
                                         isPharmacy,
                                     }) {
    const {t} = useLanguageForShared();

    return (
        <Row className="justify-content-end mt-3">
            <Col xs="auto">
                <BtnSecondary data-test="cancel-order" onClick={changeRouteProducts}>
                    {t("action.back")}
                </BtnSecondary>
            </Col>
            {isPharmacy && (
                <Col xs="auto">
                    <BtnPrimary data-test={isInCart ? "order-update-product" : "order-add-product"} onClick={addToCart}>
                        {isInCart ? t('sg.update') : t('sg.addProd')}
                    </BtnPrimary>
                </Col>
            )}
            <Col xs="auto">
                <BtnPrimary data-test="order-finalize" onClick={createOrder}>
                    {t("action.next")}
                </BtnPrimary>
            </Col>
        </Row>
    );
}
