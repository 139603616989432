import React, {useEffect} from "react";
import {SelectProductForm} from "./SelectProductForm";
import {SelectProductButtons} from "./SelectProductButtons";
import {useForm} from "../../../../../shared/utils/forms/useForm";
import {useValidationProduct} from "./templates/validations/useValidationProduct";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import http from "../../../../utils/http";
import {useSelector} from "react-redux";
import {selectSelectedCustomer} from "../../../../../redux/customer/customerSlice";

export function SelectProductFullForm(
    {
        product,
        changeQuantityProduct,
        changeRouteProducts,
        addToCart,
        createOrder,
        isInCart,
        isPharmacy
    }) {
    const customer = useSelector(selectSelectedCustomer);
    const {t} = useLanguageForShared();
    useEffect(() => {
        setData(product?.detail || {});
    }, [product?.detail]);

    const {
        dataForm,
        dataFormatted,
        changeValue,
        errors,
        setData,
        setValidations,
        isSubmit,
        isValid,
        submitAction
    } = useForm(submitHandler, validations);
    // TODO MAX  aggiungere validation

    const {validations} = useValidationProduct(product?.template, t, dataForm);

    useEffect(() => setValidations(validations), [validations]);

    const addToCartHandler = () => {
        const callback = () => {
            addToCart(dataFormatted);
            // TODO MAX  controllare prima era formik.value
        };
        if (isValid()) {
            callback();
        } else {
            submitAction();
        }
        // isValid(callback);
    };
    const createOrderHandler = () => {
        const callback = (documentUuid) => {
            if (documentUuid) {
                dataFormatted.documentUuid = documentUuid
            }
            delete dataFormatted.file
            createOrder(dataFormatted);
        };
        //
        if (isValid()) {
            // upload file + description
            const {file, description} = dataForm
            if (file && customer) {
                // {apiUrl}/v2/customer/{customerId}/documents
                http.post(`${process.env.REACT_APP_URL_V2}customer/${customer.id}/documents`, {
                    base64: file.base64,
                    name: file.name || file.file.name,
                    type: file.FileType,
                    description: description || undefined
                }).then(result => {
                    console.log('file uuid to store in the dataformatted', result)
                    callback(result);
                });
            } else {
                callback();
            }
        } else {
            submitAction();
        }
    };
    const submitHandler = (event, data) => {
    };

    return (
        <>
            <SelectProductForm
                product={product}
                changeQuantityProduct={changeQuantityProduct}
                dataForm={dataForm}
                setData={setData}
                changeValue={changeValue}
                isSubmit={isSubmit}
                errors={errors}
            />
            <SelectProductButtons
                changeRouteProducts={changeRouteProducts}
                addToCart={addToCartHandler}
                createOrder={createOrderHandler}
                isInCart={isInCart}
                isPharmacy={isPharmacy}
            />
        </>
    );
}
