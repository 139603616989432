import React, {useEffect, useRef} from "react";
import Hr from "../../../../shared/ui/lineBrake/Hr";
import {RowTotal} from "../rowTotal/RowTotal";
import {HeaderDetailOrder} from "./HeaderDetailOrder";
import {ProductListOrder} from "./ProductListOrder";

import PrintComponentPrescription from "./PrintComponentPrescription";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import PanelBottom from "../../../../../shared/custom/panelBottom/PanelBottom";

export function OrderDetailComponent({
                                         orderDetail,
                                         order,
                                         changeStateOrder,
                                         updateOrderHandler,
                                         setOrderInEdit,
                                         suspensionCallback,
                                         closeDetailHandler,
                                         noActions
                                     }) {

    const certificateTemplateRef = useRef(null);
    const {t} = useLanguageForShared();

    return (
        <div>
            <HeaderDetailOrder
                order={order}
                orderDetail={orderDetail}
                changeStateOrder={changeStateOrder}
                updateOrderHandler={updateOrderHandler}
                setOrderInEdit={setOrderInEdit}
                refComp={certificateTemplateRef}
                closeDetailHandler={closeDetailHandler}
                noActions={noActions}
            />
            <div className={"mt-5"}>
                <section>
                    <h3 className={"title-section"}>{t("sg.section.treatment")}</h3>
                    <ProductListOrder order={order} showBtnAction={true} suspensionCallback={suspensionCallback}/>
                </section>
            </div>

            <PrintComponentPrescription
                patient={orderDetail?.patient}
                order={order} suspensionCallback={suspensionCallback}
                ref={certificateTemplateRef}/>

            {/*stampa pdf*/}

            {((order.productCart || order.products) && orderDetail) && <>
                <Hr/>
                <RowTotal
                    products={order.productCart || order.products}
                    orderDetail={orderDetail}
                />
            </>}
        </div>
    );
}
