import React, { ReactChild } from 'react'
import { HeadingLayout } from './HeadingLayout'

export const TitleHeadingH3 = ({
                                 title,
                                 rightTemplate,
                               }: {
  title?: ReactChild
  rightTemplate?: ReactChild
}) => {
  return (
    (title || rightTemplate) ?
      <div className="sh-title-heading">
        <HeadingLayout
          leftTemplate={title && <h3>{title}</h3>}
          rightTemplate={rightTemplate}
        />
      </div> : null

  )
}
