import React from "react";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";
import BtnDefault from "../../../../shared/bootstrap/button/default/BtnDefault";
import configuration from "../../../../shared/configuration";
import {customerTypeName} from "../../../../redux/customer/customerData";
import {useSelector} from "react-redux";
import {selectCustomerRole, selectCustomerType} from "../../../modules/Auth/_redux/authRedux";
import * as routeName from "../../../routes/routeNameAuthType";
import {useRoute} from "../../../utils/useRoute";
import BtnSecondary from "../../../../shared/bootstrap/button/secondary/BtnSecondary";
import BtnPrimary from "../../../../shared/bootstrap/button/primary/BtnPrimary";
import {usePermissions} from "../../../shared/utils/usePemissions";
import {permissionsData} from "../../../modules/Auth/_redux/permissionsData";

const WelcomeComponent = () => {
    const {t} = useLanguageForShared();
    const customerTypeSel = useSelector(selectCustomerType);
    const customer_role = useSelector(selectCustomerRole);
    const {issetPermission} = usePermissions();

    const isBuyer = issetPermission([permissionsData.hasBuyer]);
    const isApprover = issetPermission([permissionsData.hasApprover]);
    const isBo = customer_role === customerTypeName.bo;

    const {changeRoute} = useRoute();
    const goToNewPrescription = () => {
        changeRoute(routeName.patientsPage);
    };

    return (
        <section className={'welcome-section'}>

            <div className={"mt-2 font-size-lg"} style={{maxWidth: "800px", lineHeight: "28px"}}>
                <b className={"font-size-lg"} style={{fontSize: "21px"}}>{t('sg.welcome.title')}</b>
                <br/>
                {
                    isBuyer && <div>
                        {
                            customerTypeSel === customerTypeName.prescriber &&
                            <div style={{marginBottom: 20}}>
                                <div>{t('pp.welcome.prescriber.p1')}</div>
                                <br/>
                                <div>{t('pp.welcome.prescriber.p2')}</div>
                                <ul>
                                    <li>{t('pp.welcome.prescriber.p3')}</li>
                                    <li>{t('pp.welcome.prescriber.p4')}</li>
                                </ul>
                                <div>{t('pp.welcome.prescriber.p5')}</div>
                            </div>
                        }

                        {
                            customerTypeSel === customerTypeName.pharmacy &&
                            <div>{t('pp.welcome.pharmacy.p1')}</div>
                        }
                    </div>
                }


                {customerTypeSel === customerTypeName.rdomanager &&
                    <span>{t('pp.welcome.rdomanager.p1')}<br/>
          </span>}

                {
                    isBo &&
                    <span>
                        {t('pp.welcome.bo.p1')}<br/>
                        {t('pp.welcome.bo.p2')}
                    </span>
                }

                {(isApprover && !isBuyer) &&
                    <span>{t('pp.welcome.approver.p1')}<br/>
          </span>}
            </div>

            {isBuyer && customerTypeSel === customerTypeName.prescriber &&
                <div>
                    <BtnDefault
                        className="border-0 p-2 h-auto text-capitalize"
                        onClick={goToNewPrescription}
                        icon={"report"}
                        tintColor={configuration.theme.primary}
                        iconSize={"24px"}
                    >
          <span className={"font-size-lg"}>
          {t("sg.newPrescription")}
          </span>
                    </BtnDefault>
                </div>
            }
        </section>
    );
};

export default WelcomeComponent;
