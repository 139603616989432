import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { isArray } from "../../shared/utils/arrayUtils";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";
import {
  usePathologiesTherapyData
} from "../../app/pages/products/selectProduct/component/templates/data/usePathologiesTherapyData";
import { testSNOMED } from "../../app/pages/products/selectProduct/component/templates/data/testData";
import LabelValueDownloadFile from "../../shared/custom/labelValue/LabelValueDownloadFile";
import { useDispatch } from "react-redux";
import http from "../../app/utils/http";
import { addSuccessMessage } from "../../shared/redux/errors/errorSlice";


export function TemplateScoredDiagnosticStudyRead({data, documents}: any) {
  const { t } = useLanguageForShared();
  const dispatch = useDispatch();

  const { pathologiesSNOMED } = usePathologiesTherapyData(t, data)

    const checkPathologyType = () => {
        return isArray(data?.suspectedPathologies) ? data?.suspectedPathologies?.map((ele: string) => t(`pathology.${pathologiesSNOMED[ele]}`)).join(", ") : t(`pathology.${pathologiesSNOMED[data?.suspectedPathologies]}`) || "-";
    };

    const file = documents && documents[0]

    const downloadFile = (value: any) => {
        if (!value || !file) {
            return
        }

        // /v2/customer/{customerId}/Documents/{documentId}
        http.get(`${process.env.REACT_APP_URL_V2}customer/${file.codiceCliente}/documents/${file.id}`, {}).then(result => {
            const binaryData = atob(result.base64);
            const arrayBuffer = new Uint8Array(binaryData.length).map((_, i) => binaryData.charCodeAt(i));

            let applicationType = ''
            if (result.fileType === 'pdf')
                applicationType = 'application/pdf'

            const blob = new Blob([arrayBuffer], {type: applicationType});

            // Create a link element
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob); // Create a Blob URL
            link.download = `${result.fileName}.${result.fileType}`; // Specify file name for download

            // Append link to the body, click it, and remove it
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 1000)
            dispatch(addSuccessMessage({title: t(`"${result.fileName}.${result.fileType}" ${t("action.download")}`)}))
        }).catch(err => console.error(err));
    }
    return (
        <div className={"template-sg ventilo-template row row-gap-4"}>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className={"row row-gap-4"}>
                    <div className="col-sm-12 col-md-4">
                        <LabelValue
                            label={t("prescription.template.test.label")}
                            value={data && testSNOMED[data?.test] ? t(`prescription.template.test.${testSNOMED[data?.test]}`) : '-'}/>`
                    </div>
                    {/*<div className="col-sm-12 col-md-4">
                        <LabelValue
                            label={t("prescription.template.suspectedPathologies.label")}
                            value={checkPathologyType() || '-'}/>
                    </div>*/}
                    <div className="col-sm-12 col-md-2">
                        <LabelValue label={t("prescription.template.urgent")}
                                    value={data?.urgent ? t("common.yes") : t("common.no")} className={"me-5"}/>
                    </div>
                    {/*<div className="col-sm-12 col-md-2">
                        <LabelValue label={t("prescription.template.pediatric")}
                                    value={data?.pediatric ? t("common.yes") : t("common.no")} className={"me-5"}/>
                    </div>*/}
                </div>
            </div>
          <div className={"row row-gap-4"}>
            <div className="col-sm-12 col-md-4">
              <LabelValue
                label={t("prescription.template.testExecutionAddress.label")}
                value={data?.testExecutionAddress || '-'} />
            </div>
            <div className="col-sm-12 col-md-3">
              <LabelValue
                label={t("prescription.template.clinicalCenter.label")}
                value={data?.clinicalCenter || '-'} />
            </div>
            <div className="col-sm-12 col-md-3">
              <LabelValue
                label={t("prescription.template.request.label")}
                value={data?.request || '-'} />
            </div>
          </div>

          <div className={"row row-gap-4"}>
            <div className="col-sm-12 col-md-4">
              <LabelValue label={t("prescription.template.note.label")} value={data?.note} />
            </div>
            {
                    file && <div className="col-sm-12 col-md-3">
                        <LabelValueDownloadFile
                            label={t("prescription.template.file.label")}
                            value={`${file.displayName}.${file.mimeType}`}
                            clickIconValueHandler={(val) => downloadFile(val)}
                        ></LabelValueDownloadFile>
                    </div>
                }
            </div>
        </div>
    );
}
