import { devicesMapped } from "./deviceData";

export const useTherapyData = (t: any, dataForm: any) => {


  const formStructureDevice = [
    {
      name: "modelDevice",
      type: "select",
      label: t(`prescription.template.therapy.modelDevice.label`),
      options: Object.keys(devicesMapped).map(ele => ({
          label: devicesMapped[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.therapy.modelDevice.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    },
  ];


  /*const createElementForm = (ele: any) => {
    return {
      type: "input",
      label: t(`prescription.template.therapy.${ele.name}.label`),
      dataElement: {
        placeholder: t(`prescription.template.therapy.${ele.name}.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
      ...ele
    };
  };

  const formStructureDevice = [/!*{ name: "guaranteedVolume" },*!/ { name: "modelDevice" }].map(ele => createElementForm(ele));
*/

  return {
    formStructureDevice
  };
};
