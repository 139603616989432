import React, {ReactChild} from "react";
import {useLanguageForShared} from "../../utils/languages/useLanguageForShared";
import configuration from "../../configuration";
import LabelValue from "./LabelValue";
import {IconSVG} from "../icon/IconSVG";

const LabelValueDownloadFile = ({
                                    label,
                                    value,
                                    clickIconValueHandler,
                                    ...rest
                                }: {
    label: string | ReactChild,
    value?: string,
    clickIconValueHandler?: (value?: string) => void,
    labelClass?: string
}) => {
    const {t} = useLanguageForShared();
    const iconDefault = {ico: "download", size: "14px", color: configuration.theme.primary};
    return (
        <div className={"pointer"} style={{display: "flex", alignItems: "center"}}>
            <LabelValue className={"position-relative"}
                        label={label}
                        value={<span>{value}</span>} {...rest} />
            <div className={"mt-2"}>
                <IconSVG
                    icon={iconDefault.ico}
                    size={iconDefault?.size}
                    tintColor={iconDefault?.color}
                    title={t("action.download")}
                    onClick={() => clickIconValueHandler && clickIconValueHandler(value)}/>
            </div>
        </div>
    );
}

export default LabelValueDownloadFile;
