import {cfRegex} from "./../../../../utils/cfRegex";
import * as Yup from "yup";
import {getTodayDate, toEnDateString} from "../../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());

export const phone = Yup.object().shape({
        phone: Yup.number()
            .typeError("Inserire un numero valido")
            .when("mobile", {
                is: (mobile) => !mobile || mobile.length === 0,
                then: Yup.number().typeError("Inserire un numero valido").required("At least one of the fields is required"),
            }).nullable(),
        mobile: Yup.number()
            .typeError("Inserire un numero valido")
            .when("phone", {
                is: (mobile) => !mobile || mobile.length === 0,
                then: Yup.number().typeError("Inserire un numero valido").required("At least one of the fields is required"),
            }).nullable(),
    },
    ["phone", "mobile"]
);

//
const patientFiscalCodeValidation = (t) => {
    if (!process.env.REACT_APP_VALIDATIONS_PATIENT_CF || process.env.REACT_APP_VALIDATIONS_PATIENT_CF === "true") {
        return Yup.string()
            .matches(cfRegex, t('pp.errors.msg.valid_fiscal_code'))
            .required(t('pp.errors.msg.required')).nullable()
    }
    return Yup.string().required(t('pp.errors.msg.required')).nullable()
}

export const validationsPatient = (t) => Yup.object().shape({
        name: Yup.string().required(t('pp.errors.msg.required')).nullable(),
        surname: Yup.string().required(t('pp.errors.msg.required')).nullable(),
        cf: patientFiscalCodeValidation(t),
        birthDate: Yup.date()
            .max(todayDate, t('pp.errors.msg.valid_date_max_today'))
            .required(t('pp.errors.msg.required')).nullable(),
        email: Yup.string().email(t('pp.errors.msg.valid_mail')).nullable(),
        phone: Yup.number()
            .typeError("Inserire un numero valido")
            .when("mobile", {
                is: (mobile) => !mobile,
                then: Yup.number().typeError("Inserire un numero valido").required(t('pp.errors.msg.compile_one_field')),
            }).nullable(),
        mobile: Yup.number()
            .typeError("Inserire un numero valido")
            .when("phone", {
                is: (phone) => !phone,
                then: Yup.number().typeError("Inserire un numero valido").required(t('pp.errors.msg.compile_one_field')),
            }).nullable(),
        residence: Yup.object().shape({
            number: Yup.mixed().nullable(),
            internal: Yup.number().typeError(t('pp.errors.msg.valid_number')).nullable(),
            cap: Yup.string()
                    .matches(
                        /^(\d{5}|[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2})$/i,
                        t('pp.errors.msg.valid_postal_code')
                    )
                    .nullable()
        }),
        domicile: Yup.object().shape({
            number: Yup.mixed().nullable(),
            internal: Yup.number().typeError(t('pp.errors.msg.valid_number')).nullable(),
            cap: Yup.string()
                    .matches(
                        /^(\d{5}|[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2})$/i,
                        t('pp.errors.msg.valid_postal_code')
                    )
                    .nullable()
        }).nullable(),
    },
    ["phone", "mobile"]
);
