import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import FiltersCol from "../../shared/design/history/FilterSidebar/FiltersCol";
import HistoryDetail from "./components/HistoryDetail";
import { IconSVG } from "../../../shared/custom/icon/IconSVG";
import configuration from "../../../shared/configuration";

export function HistoricalPage({ history }) {
  const formik = useFormik({
    initialValues: {
      //dateBy: prevDayDate(new Date().getTime()).toISOString(),
    },
    onSubmit: (values) => {
    }
  });
  const heightContent = `calc(100vh - 75px)`;

  const [filterColClose, setFilterColClose] = useState(false)
  const toggleFiltersCol = () => {
    setFilterColClose(!filterColClose)
  }
  return (
    <div className="row-history">
      <div className={`col-filters ${filterColClose ? 'closed' : 'open'}`}>
        <Card className="card mb-0">
          <CardBody style={{ height: heightContent, overflow: "auto" }}>
            <div className={'action-filter d-flex justify-content-end'}>
              <div className={'pointer'} onClick={toggleFiltersCol}>
                <IconSVG icon={'filters'} size={'20px'} tintColor={configuration.theme.primary} />
              </div>
            </div>
            <Row className={'filters-row'}>
              <Col xs="12">
                <FiltersCol formik={formik} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="col-history">
        <HistoryDetail filters={formik.values} />
      </div>
    </div>
  );
}
