import Label from "../../../../../shared/bootstrap/form/label/Label";
import Error from "../../../../../shared/bootstrap/form/error/Error";
import React, {useState} from "react";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import {classByArray} from "../../../../../shared/functions/function";
import {getBase64ToString} from "../../../../../shared/utils/fileUtils";
import * as PropTypes from "prop-types";

function BtnDefault(props) {
    return null;
}

BtnDefault.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.any,
    tintColor: PropTypes.string,
    iconSize: PropTypes.string
};

interface UploadComponentProps {
    error?: any
    setUploadFile: (file: any) => void
    setDescriptionFile: (description: string) => void
}

export function UploadComponent({error, setUploadFile, setDescriptionFile}: UploadComponentProps) {
    const {t} = useLanguageForShared();
    const [description, setDescription] = useState('');
    const classArray = ['sh form-control', ...(error ? ['error'] : [])]

    const handleFileChange = (event) => {
        const files = event.target.files
        if (files.length) {
            const file = files[0];
            getBase64ToString(file).then((res) => {
                const fileArray = file.name.split('.');
                const data = {
                    file,
                    base64: res,
                    name: fileArray.filter((ele, index) => index < fileArray.length - 1).join(''),
                    FileType: fileArray[fileArray.length - 1],
                }
                setUploadFile(data)
            })
        } else {
            setUploadFile(undefined)
        }
    };

    return (
        <>
            <div className="row form-element-row upload-component">
                <div className="form-group col-md-6">
                    <div className={''}>
                        <Label label={t(`prescription.template.upload.label`)}/>

                        <input
                            type={'file'}
                            name={'file-upload'}
                            className={classByArray(classArray)}
                            onChange={handleFileChange}
                            accept="application/pdf"
                            data-test={'input-file-upload'}
                        />

                        <div className={''}>
                            {error && <Error {...error} />}
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <div className={''}>
                        <div className={''}>
                            <Label
                                label={t(`prescription.template.upload_description.label`)}
                            />
                        </div>

                        <input
                            type={'text'}
                            className={classByArray(classArray)}
                            name={'description'}
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value)
                                setDescriptionFile(event.target.value)
                            }}
                            data-test={'input-text'}
                            placeholder={t(`prescription.template.upload_description.placeholder`)}
                        />
                        <div className={''}>
                            {error && <Error {...error} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}