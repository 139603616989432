import React, { useEffect } from "react";
import { useLanguageForShared } from "../../../../../../shared/utils/languages/useLanguageForShared";
import { FormElements } from "../../../../../../shared/bootstrap/form/FormElements";
import { useModeTherapyData } from "./data/useModeTherapyData";
import { useTherapyData } from "./data/useTherapyData";
import { useEffectOnlyUpdate } from "../../../../../../shared/utils/use_custom/useEffectCustom";
import { copyObject } from "../../../../../../shared/utils/objUtils";
import { useParamsTharapyData } from "./data/useParamsTherapyData";
import { useParamsTitrationData } from "./data/useParamsTitrationData";

export function TemplateTitration({dataForm, changeValue, errors, setData, isSubmit}) {
    const {t} = useLanguageForShared()
    console.log(dataForm)
    const {formStructureMode, formStructureModeChoose} = useModeTherapyData(t, dataForm);
    const {formStructureDevice} = useTherapyData(t, dataForm);
    const {
        formStructureMask,
        formStructureDetailHumidifier,
        formStructureHumidifier,
        formStructureNote,
        formStructureRampa,
        formStructureDetailRampa,
        formStructureDate
    } = useParamsTitrationData(t, dataForm)

    useEffectOnlyUpdate(() => {
        if (!dataForm.interface) {
            const modeField = formStructureDetailRampa.map((ele) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.rampa]);


    useEffectOnlyUpdate(() => {
        if (!dataForm.humidifier) {
            const modeField = formStructureDetailHumidifier.map((ele) => ele.name).join(',').split(',')
            const copyDataForm = copyObject(dataForm)
            modeField?.forEach((key) => {
                delete copyDataForm[key]
            })
            setData(copyDataForm)
        }
    }, [dataForm.humidifier]);

    useEffect(() => {
        if (dataForm.mode) {
            const copyDataForm = copyObject(dataForm)
            delete copyDataForm?.pressure
            delete copyDataForm?.press_min
            delete copyDataForm?.press_max
            setData(copyDataForm)
        }
    }, [dataForm?.mode]);


    return (
        <>
            <div className={'w-100'}>
                <FormElements
                  isSubmit={isSubmit}
                  structure={formStructureDate}
                  dataForm={dataForm}
                  errors={errors}
                  changeValue={changeValue}
                />
            </div>
            <div className={'d-flex'}>
                <div className={'w-100'}>
                    <FormElements
                        isSubmit={isSubmit}
                        structure={formStructureMode}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}
                    />
                </div>
                <div className={'w-100'}>
                    <FormElements
                        isSubmit={isSubmit}
                        structure={formStructureDevice}
                        dataForm={dataForm}
                        errors={errors}
                        changeValue={changeValue}/>
                </div>
            </div>
            <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
            <FormElements
                structure={formStructureMask}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
                isSubmit={isSubmit}
            />
            <FormElements
                isSubmit={isSubmit}
                structure={formStructureRampa}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
            />
            <FormElements
                structure={formStructureHumidifier}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
                isSubmit={isSubmit}
            />
            <FormElements
                structure={formStructureNote}
                dataForm={dataForm}
                changeValue={changeValue}
                errors={errors}
                isSubmit={isSubmit}
            />
        </>
    );
}
