import { maskMapped } from "./maskData";
import { partsData } from "./partsData";

export const useGenericProductData = (t: any, dataForm: any) => {
  const formStructureModel = [
    {
      name: "model",
      type: "select",
      label: t(`prescription.template.therapy.model.label`),
      options: Object.keys(partsData).map(ele => ({
          label: partsData[ele],
          value: ele
        })
      ),
      dataElement: {
        placeholder: t(`prescription.template.therapy.model.placeholder`),
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "6", xl: "6" },
    },
  ];
  const formStructureQuantity = [
    {
      name: "quantity",
      type: "input",
      label: t(`prescription.template.therapy.quantity.label`),
      dataElement: {
        placeholder: t(`prescription.template.therapy.quantity.placeholder`),
        type: "number"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" },
    },
  ];

  return {
    formStructureModel,
    formStructureQuantity
  };
};
