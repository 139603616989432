import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {
    resetOrderData,
    selectCurrentOrder,
    selectFromLsi,
    selectIsEditOrder,
    selectOrderData
} from "../../../redux/order/orderSlice";
import {saveOrder} from "../../../redux/order/orderCrud";
import {selectIsPharmacy, selectSelectedCustomer} from "../../../redux/customer/customerSlice";
import {SummaryOrder} from "./component/summary/SummaryOrder";
import {useRoute} from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import {DetailOrderTemplate} from "./DetailOrderTemplate";
import {GetCartAction} from "../../../redux/cart/cartActions";
import {Col, Container, Row} from "react-bootstrap";
import {resetModal, setModal} from "../../shared/redux/modal/modalSlice";
import {resetHomeDelivery, selectPatient, setHomeDelivery} from "../../../redux/patient/patientSlice";
import {SummaryLightOrder} from "./SummaryLightOrder";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import {nameSteps} from "../../shared/app/wizard_order/wizardOrderData";
import {copyObject} from "../../utils/objUtils";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import {UseTitleApp} from "../../core/useTitleApp";
import {pages} from "../../core/titleApp";
import {setErrorTypeAction} from "../../../redux/errors/errorsActions";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";

export const orderState = {
    DRAFT: 0,
    SENT: 3
}

export function OrderPage({history}) {
    const {t} = useLanguageForShared();

    const dispatch = useDispatch();
    const order = useSelector(selectCurrentOrder);
    const isEditOrder = useSelector(selectIsEditOrder);
    // const orderFinal = useSelector(selectOrderData);
    const customer = useSelector(selectSelectedCustomer);
    const isPharmacy = useSelector(selectIsPharmacy);
    const patient = useSelector(selectPatient);
    const [orderDetail, setOrderDetail] = useState(undefined);
    const [showSummary, setShowSummary] = useState(false);
    const isFromLsi = useSelector(selectFromLsi);
    const {changeRoute} = useRoute();

    useEffect(() => {
        (!order || !Object.keys(order)?.length) &&
        changeRoute(isPharmacy ? routeName.cart : routeName.patientsPage);
        order?.orderDetail && setOrderDetail(order);
        return () => dispatch(resetOrderData());
    }, []);
    useEffect(() => {
        return () => dispatch(resetHomeDelivery())
    }, [])
    useEffect(() => {
        orderDetail?.orderDetail?.patient ? dispatch(setHomeDelivery(orderDetail?.orderDetail?.patient)) : dispatch(resetHomeDelivery());
    }, [orderDetail?.orderDetail?.patient]);

    const createSummary = (orderDetail) => {
        setOrderDetail({
            orderDetail: orderDetail,
            productCart: order.products,
            orderRequest: order.orderRequest,
            type: order.type,
            customerId: customer.id,
            rdoId: order.rdoId,
            documents: order.products[0].detail.documentUuid ? [order.products[0].detail.documentUuid] : undefined
        });
        setShowSummary(true);
    };

    const confirmSendOrder = () => {
        dispatch(resetModal());
        if (isFromLsi) {
            window.location.href = `${process.env.REACT_APP_URL_LSI}pazienti/${patient.id}/dettaglio`
        } else {
            isPharmacy
                ? changeRoute(routeName.products)
                : changeRoute(routeName.patientsPage);
        }
    };

    const savedOrderAsDraft = () => {
        dispatch(resetModal());
        changeRoute(routeName.historical)
    }

    const showModalTo = (isDraft = false) => {
        if (isDraft) {
            dispatch(
                setModal(
                    [
                        {
                            label: t("action.close"),
                            action: "savedOrderAsDraft",
                            type: "primary",
                            dataType: {}
                        }
                    ],
                    {
                        title: t("sg.msg.modals.savedAsDraft")
                    },
                    {
                        savedOrderAsDraft
                    }
                )
            );
        } else {
            dispatch(
                setModal(
                    [
                        {
                            label: isFromLsi ? t("sg.backToPatientSheet") : `${t("action.close")}`,
                            action: "confirmSendOrder",
                            type: "primary",
                            dataType: {}
                        }
                    ],
                    {
                        title: `${isPharmacy ? t("sg.msg.modals.orderSend") : t("sg.msg.modals.requestSend")}`
                    },
                    {
                        confirmSendOrder
                    }
                )
            );
        }
    };

    const createOrder = (draft = undefined) => {
        if (draft !== undefined) {
            if (draft) {
                orderDetail.state = orderState.DRAFT
                orderDetail.rdoId = orderDetail.productCart[0].detail.rdoId ? orderDetail.productCart[0].detail.rdoId : undefined
            } else {
                orderDetail.state = orderState.SENT
            }
        }

        // orderDetail.state = orderDetail.productCart[0].detail.state;
        // orderDetail.rdoId = orderDetail.productCart[0].detail.rdoId;
        const data = copyObject(orderDetail);
        saveOrder(data)
            .then((res) => {
                dispatch(GetCartAction(t));
                showModalTo(orderDetail.state === orderState.DRAFT);
            })
            .catch((error) => {
                dispatch(setErrorTypeAction(t("sg.msg.errorSave")));
            });
    };

    const goBack = () => changeRoute(isEditOrder ? routeName.selectEditProduct : (isPharmacy ? routeName.cart : routeName.selectProduct))

    const {label} = UseTitleApp(t, !orderDetail || !showSummary ? pages.summary : pages.summaryFinal, isPharmacy)

    return (
        <Container>
            <Row>
                <Col xs="12">
                    <WizardOrder
                        selectedStep={showSummary ? nameSteps.summary : nameSteps.finalize}
                    />
                    <Card>
                        <CardHeader>
                            {/* {(patient || orderDetail?.orderDetail?.patient) && (
								<UserDetail customer={patient || orderDetail?.orderDetail?.patient}/>
							)} */}
                            <TitleCard title={label.title} subtitle={label.subtitle}/>
                        </CardHeader>
                        <CardBody>
                            {(!orderDetail || !showSummary) && (
                                <Row>
                                    <Col xs="12" md="12" lg="3" className="mb-5 px-4">
                                        <SummaryLightOrder
                                            isPharmacy={isPharmacy}
                                            products={order?.products}
                                        />
                                    </Col>
                                    <Col xs="12" md="12" lg="9">
                                        <DetailOrderTemplate
                                            goBack={goBack}
                                            customer={customer}
                                            createOrder={createSummary}
                                            order={orderDetail?.orderDetail}
                                            templateRDO={order?.orderRequest?.templateRDO}
                                            patient={patient}
                                        />
                                    </Col>
                                </Row>
                            )}

                            {orderDetail && showSummary && (
                                <SummaryOrder
                                    orderDetail={orderDetail.orderDetail}
                                    order={order}
                                    createOrder={createOrder}
                                    resetSummary={() => setShowSummary(false)}
                                />
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
