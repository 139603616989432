import React from "react";
import { templateNameId, templateNameType } from "./templateData";
import { TemplateNoteRead } from "./templateRead/TemplateNoteRead";
import { TemplateOxigenRead } from "./templateRead/TemplateOxigenRead";
import { TemplateVentilotherapyRead } from "./templateRead/TemplateVentilotherapyRead";
import { TemplateSleepRead } from "./templateRead/TemplateSleepRead";
import { TemplateConcentratorRead } from "./templateRead/TemplateConcentratorRead";
import { TemplateSleepStudyRead } from "./templateRead/TemplateSleepStudyRead";
import { TemplateScoredDiagnosticStudyRead } from "./templateRead/TemplateScoredDiagnosticStudyRead";
import { TemplateTherapyRead } from "./templateRead/TemplateTherapyRead";
import { TemplateTitrationRead } from "./templateRead/TemplateTitrationRead";
import { TemplateMaskProductRead } from "./templateRead/TemplateMaskProductRead";
import { TemplateGenericProductRead } from "./templateRead/TemplateGenericProductRead";
import { TemplateTherapyNivRead } from "./templateRead/TemplateTherapyNivRead";

export function TemplateSelectProductRead({ template, data, documents }) {
  const templateList = {
    [templateNameId[templateNameType.note]]: <TemplateNoteRead data={data} />,
    [templateNameId[templateNameType.ossigeno]]: <TemplateOxigenRead data={data} />,
    [templateNameId[templateNameType.ventiloterapia]]: <TemplateVentilotherapyRead data={data} />,
    [templateNameId[templateNameType.sleep]]: <TemplateSleepRead data={data} />,
    [templateNameId[templateNameType.concentrator]]: <TemplateConcentratorRead data={data} />,
    [templateNameId[templateNameType.sleepStudy]]: <TemplateSleepStudyRead data={data} />,
    [templateNameId[templateNameType.therapy]]: <TemplateTherapyRead data={data} />,
    [templateNameId[templateNameType.scoredDiagnosticStudy]]: <TemplateScoredDiagnosticStudyRead data={data} documents={documents} />,
    [templateNameId[templateNameType.titration]]: <TemplateTitrationRead data={data} />,
    [templateNameId[templateNameType.maskProduct]]: <TemplateMaskProductRead data={data} />,
    [templateNameId[templateNameType.genericProduct]]: <TemplateGenericProductRead data={data} />,
    [templateNameId[templateNameType.nivTherapy]]: <TemplateTherapyNivRead data={data} />,
  };
  return (
    <>
      {templateList[template]}
    </>
  );
}
