import {getTodayDate, toEnDateString} from "../../../utils/dateUtils";

const todayDate = toEnDateString(getTodayDate());
export const formStructureEditPatientRegistryRow = (t) => [
    {
        name: "surname",
        label: t('sg.patient.lastName'),
        type: "input",
    },
    {
        name: "name",
        label: t('sg.patient.firstName'),
        type: "input",
    },
    {
        name: "cf",
        label: t('sg.patient.fiscalCodeSm'),
        type: "input",
    },
    {
        name: "birthDate",
        label: t('sg.patient.birthDate'),
        type: "input",
        dataElement: {
            type: "date",
            max: todayDate,
        },
    },
    {
        name: "phone",
        label: t('sg.patient.phone'),
        type: "input",
    },
    {
        name: "mobile",
        label: t('sg.patient.mobile'),
        type: "input",
    },
    {
        name: "email",
        label: t('sg.patient.email'),
        type: "input",
    },
].map((ele) => ({...ele, col: {xs: "12", sm: "6", lg: "3"}}));

export const formStructureEditPatientRegistryRowResidence = (t) => [
    {
        name: "residence.street",
        label: t('sg.patient.residenceAddress'),
        type: "input",
        col: {xs: "12", sm: "6", lg: "3"},
    },
    {
        name: "residence.number",
        label: t('sg.patient.number'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "residence.internal",
        label: t('sg.patient.int'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "residence.cap",
        label: t('sg.patient.zip'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "residence.city",
        label: t('sg.patient.city'),
        type: "input",
        col: {xs: "12", sm: "6", lg: "3"},
    },
    {
        name: "residence.province",
        label: t('sg.patient.province'),
        type: "input",
        col: {xs: "12", sm: "3", lg: "1"},
    },
];
export const formStructureEditPatientRegistryRowDomicile = (t) => [
    {
        name: "domicile.street",
        label: t('sg.patient.domicileAddress'),
        type: "input",
        col: {xs: "12", sm: "6", lg: "3"},
    },
    {
        name: "domicile.number",
        label: t('sg.patient.number'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "domicile.internal",
        label: t('sg.patient.int'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "domicile.cap",
        label: t('sg.patient.zip'),
        type: "input",
        col: {xs: "4", sm: "3", lg: "1"},
    },
    {
        name: "domicile.city",
        label: t('sg.patient.city'),
        type: "input",
        col: {xs: "12", sm: "6", lg: "3"},
    },
    {
        name: "domicile.province",
        label: t('sg.patient.province'),
        type: "input",
        col: {xs: "12", sm: "3", lg: "1"},
    },
];
