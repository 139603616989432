import { modeNivTherapyName } from "./modeData";
import { useModeDataNivTherapy } from "./useModeDataNivTherapy";

export const useModeNivTherapyData = (t: any, dataForm: any) => {
  const { formStructureModeChoose } = useModeDataNivTherapy(t);

  const formStructureMode = [
    {
      name: "mode",
      type: "select",
      label: `${t("prescription.template.mode.label")}*`,
      options: Object.keys(modeNivTherapyName)?.map(ele => ({
          label: t(`prescription.template.${ele}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" }
    },
    ...(dataForm?.mode && formStructureModeChoose && formStructureModeChoose[dataForm?.mode]
      ? formStructureModeChoose[dataForm?.mode]
      : [])];

  return {
    formStructureMode,
    formStructureModeChoose
  };
};
