import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import { useLanguageForShared } from "../../shared/utils/languages/useLanguageForShared";
import { modeChooseTherapy } from "../modeDataForm";
import { maskMapped } from "../../app/pages/products/selectProduct/component/templates/data/maskData";
import { devicesMapped } from "../../app/pages/products/selectProduct/component/templates/data/deviceData";


export function TemplateTherapyRead({data}: any) {
    const {t} = useLanguageForShared();

    const structureModeChoose = Object.keys(modeChooseTherapy).reduce((acc: any, mode) => {
        return {
            ...acc, [mode]: modeChooseTherapy[mode].map((ele: string) => (ele))
        };
    }, {});

    return (
        <div className={"template-sg ventilo-template row row-gap-4"}>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                <div className={"row row-gap-4"}>
                    <div className="col-sm-12 col-md-12">
                        <LabelValue label={t("prescription.template.mode.label")}
                                    value={data ? t(`prescription.template.${data?.mode}`) : '-'}/>
                        {structureModeChoose[data?.mode]?.map((ele: any, k: number) => <LabelValue key={k}
                                                                                                   label={t(`prescription.template.${ele.name}.label`)}
                                                                                                   value={data[ele.name] || '-'}/>)}
                    </div>

                    <div className="col-sm-12 col-md-4">
                        {data?.modelDevice && <LabelValue
                          label={t("prescription.template.modelDevice.label")}
                          value={devicesMapped[data?.modelDevice] || '-'} />}
                    </div>

                </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-5">
                <div className={"row row-gap-4"}>
                    <div className="col-sm-12 col-md-12">
                        <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>

                        <div className="col-sm-12 col-md-12">
                          <LabelValue label={t("prescription.template.mask.label")}
                                      value={data?.mask ? t("common.yes") : t("common.no")} className={"me-5"} />
                            {
                              data?.mask && <>
                                <LabelValue
                                  label={t("prescription.template.maskModel.label")}
                                  value={maskMapped[data?.maskModel] || "-"} className={"me-5"} />
                              </>
                            }
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <LabelValue label={t("prescription.template.rampa.label")}
                                      value={data?.rampa ? t("common.yes") : t("common.no")}
                                        className={"me-5"}/>
                            {
                              data?.rampa && <>
                                    <LabelValue
                                        label={t("prescription.template.initialPress.label")}
                                        value={data?.initialPress || "-"} className={"me-5"}/>
                                    <LabelValue
                                        label={t("prescription.template.duration.label")}
                                        value={data?.duration || "-"} className={"me-5"}/>
                                    <LabelValue
                                      label={t("prescription.template.rampaType.label")}
                                      value={data?.rampaType || "-"} className={"me-5"} />
                                </>
                            }
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <LabelValue label={t("prescription.template.humidifier.label")}
                                        value={data?.humidifier ? t("common.yes") : t("common.no")} className={"me-5"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"row row-gap-4"}>
                <div className="col-sm-12 col-md-12">
                    <LabelValue label={t("prescription.template.note.label")}
                                value={data?.note}/>

                </div>
            </div>
        </div>
    );
}
