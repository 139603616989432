import React from "react";
import Caption from "../headings/caption/Caption";
import HeadingH3 from "../headings/headingH3/HeadingH3";
import Icon from "../icon/Icon";
import Hr from "../lineBrake/Hr";
import {useDispatch} from "react-redux";
import {useRoute} from "../../../utils/useRoute";
import * as routeName from "../../../routes/routeNameAuthType";
import {setSelectedProduct} from "../../../../redux/products/productsSlice";
import HeadingH4 from "../headings/headingH4/HeadingH4";
import {IconSVG} from "../../../../shared/custom/icon/IconSVG";
import configuration from "../../../../shared/configuration";
import ThumbImage from "../../../component/imageCard/ThumbImage";
import {useLanguageForShared} from "../../../../shared/utils/languages/useLanguageForShared";

const ProductCard = ({product, disabled = false}) => {
    const {t} = useLanguageForShared();
    const dispatch = useDispatch();
    const {changeRoute} = useRoute();
    const addToCart = () => {
        dispatch(setSelectedProduct(product));
        changeRoute(routeName.selectProduct);
    };

    const cleanTitle = (text) => {
        if (text) {
            text = text.replace(/\s/g, '').replace(/\//g, '')
        }
        return text
        // CPAP/APAP Treatment Titration
    }

    return (
        <div className={`shared-product-card ${disabled && "disabled"} `}>
            <div className="thumb-info">
                <div className="thumb">
                    <ThumbImage product={product}/>
                </div>
            </div>
            <div className="info-product">
                <div className="head-info-product">
                    <div className="title-product">
                        <HeadingH3>{t(`pp.products_type.${cleanTitle(product?.title)}`)}</HeadingH3>
                        {/* <Hr /> */}
                        <Caption className="lg" style={{"lineHeight": "24px"}}>
                            {product?.descriptionSummary}
                        </Caption>
                        <Caption className="mt-3 text-sm" style={{"lineHeight": "24px"}}>
                            {product?.description}
                        </Caption>
                        <span className="mt-3 text-sm">Cod. {product?.codeSG}</span>
                    </div>
                    <div>
                        {!disabled && (
                            <IconSVG
                                icon="arrow-right"
                                tintColor={configuration.theme.primary}
                                onClick={addToCart}
                                size={"20px"}
                                className={"pointer"}
                            />
                        )}
                    </div>
                </div>
                <div className="footer-info-product">
                    {product?.price && (
                        <Caption className="bold lg">
                            € {product.price.toFixed(2)}
                        </Caption>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
