export const maskMapped = {
  '1090420': "Amara Gel P",
  '1090421': "Amara Gel S",
  '1090425': "Amara Gel M",
  '1090426': "Amara Gel L",
  '1090602': "Amara View S",
  '1090603': "Amara View M",
  '1090604': "Amara View L",
  '1137916': "Dream Wisp",
  '1133375': "DreamWear Full Face S",
  '1133376': "DreamWear Full Face M",
  '1133378': "DreamWear Full Face MW",
  '1133377': "DreamWear Full Face L",
  '1133400': "DreamWear Full Face Fitpack",
  '1144112': "DreamWear UTN Multi S",
  '1144113': "DreamWear UTN Multi M",
  '1144114': "DreamWear UTN Multi L",
  '1116700': "DreamWear UTN Nsl Fitpack",
  '1146468': "DreamWear Silicone Pillows Fitpack",
  '1116685': "DreamWear UTN S",
  '1116686': "DreamWear UTN M",
  '1116687': "DreamWear UTN L",
  '1116688': "DreamWear UTN MW",
  '1060803': "FitLife S",
  '1060804': "FitLife L",
  '1089994': "FitLife XL",
  '1105161': "Nuance Gel",
  '1105168': "Nuance Gel Pro",
  '1094061': "Wisp Silicone Frame",
  '1094060': "Wisp Fabric Frame"
}