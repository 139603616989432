import React, {useEffect} from 'react';
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useTranslation} from 'react-i18next'

import {copyObject} from "../../../../../../shared/utils/objUtils";
import {useParamsData} from "./data/useParamsData";


const ParamsTemplate = ({
                            dataForm,
                            errors,
                            changeValue,
                            setData
                        }: {
        dataForm?: any,
        errors: any,
        changeValue: (data: any) => void,
        setData: (data: any) => void
    }) => {
        const {t} = useTranslation()
        const {
            formStructureDetailInterface,
            formStructureInterface,
            formStructureNote
        } = useParamsData(t, dataForm)

        useEffect(() => {
            // se interface è false svuota i campi del tamplate interface
            if (!dataForm.interface) {
                const modeField = formStructureDetailInterface.map((ele: any) => ele.name).join(',').split(',')
                const copyDataForm = copyObject(dataForm)
                modeField?.forEach((key: string) => {
                    delete copyDataForm[key]
                })
                setData && setData(copyDataForm)
            }
        }, [dataForm.interface]);
        return (
            <div>
                <FormElements
                    structure={formStructureInterface}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
                <FormElements
                    structure={formStructureNote}
                    dataForm={dataForm}
                    errors={errors}
                    changeValue={changeValue}
                />
            </div>
        );
    }
;

export default ParamsTemplate;
