import React from "react";
import LabelValue from "../../shared/custom/labelValue/LabelValue";
import {isArray} from "../../shared/utils/arrayUtils";
import {useLanguageForShared} from "../../shared/utils/languages/useLanguageForShared";
import {
    usePathologiesTherapyData
} from "../../app/pages/products/selectProduct/component/templates/data/usePathologiesTherapyData";
import {testSNOMED} from "../../app/pages/products/selectProduct/component/templates/data/testData";


export function TemplateSleepStudyRead({data}: any) {
    const {t} = useLanguageForShared();
    const {pathologiesSNOMED} = usePathologiesTherapyData(t, data)

    const checkPathologyType = () => {
        return isArray(data?.suspectedPathologies) ? data?.suspectedPathologies?.map((ele: string) => t(`pathology.${pathologiesSNOMED[ele]}`)).join(", ") : t(`pathology.${pathologiesSNOMED[data?.suspectedPathologies]}`) || "-";
    };

    return (
        <div className={"template-sg ventilo-template row row-gap-4"}>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                <div className={"row row-gap-4"}>
                    <div className="col-sm-12 col-md-3">
                        <LabelValue
                            label={t("prescription.template.test.label")}
                            value={data && testSNOMED[data?.test] ? t(`prescription.template.test.${testSNOMED[data?.test]}`) : '-'}/>`
                    </div>
                    {/*<div className="col-sm-12 col-md-3">
                        <LabelValue
                            label={t("prescription.template.suspectedPathologies.label")}
                            value={checkPathologyType() || '-'}/>
                    </div>*/}
                    <div className="col-sm-12 col-md-3">
                        <LabelValue label={t("prescription.template.urgent")}
                                    value={data?.urgent ? t("common.yes") : t("common.no")} className={"me-5"}/>
                    </div>
                    {/*<div className="col-sm-12 col-md-3">
                        <LabelValue label={t("prescription.template.pediatric")}
                                    value={data?.pediatric ? t("common.yes") : t("common.no")} className={"me-5"}/>
                    </div>*/}
                </div>
            </div>
            <div className={"row row-gap-4"}>
                <div className="col-sm-12 col-md-3">
                    <LabelValue
                        label={t("prescription.template.testExecutionAddress.label")}
                        value={data?.testExecutionAddress || '-'}/>
                </div>
                <div className="col-sm-12 col-md-3">
                    <LabelValue
                        label={t("prescription.template.clinicalCenter.label")}
                        value={data?.clinicalCenter || '-'}/>
                </div>
            </div>


            <div className={"row row-gap-4"}>
                <div className="col-sm-12 col-md-12">
                    <LabelValue label={t("prescription.template.note.label")}
                                value={data?.note}/>

                </div>
            </div>
        </div>
    );
}
