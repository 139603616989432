import React, { useEffect } from "react";
import { useLanguageForShared } from "../../../../../../shared/utils/languages/useLanguageForShared";
import { FormElements } from "../../../../../../shared/bootstrap/form/FormElements";
import { useEffectOnlyUpdate } from "../../../../../../shared/utils/use_custom/useEffectCustom";
import { copyObject } from "../../../../../../shared/utils/objUtils";
import { useParamsTharapyData } from "./data/useParamsTherapyData";
import { useNivTherapyData } from "./data/useNivTherapyData";
import { useModeNivTherapyData } from "./data/useModeNivTherapyData";

export function TemplateNivTherapy({ dataForm, changeValue, errors, setData, isSubmit }) {
  const { t } = useLanguageForShared()
  console.log(dataForm)
  const { formStructureMode } = useModeNivTherapyData(t, dataForm);
  const { formStructureDevice } = useNivTherapyData(t, dataForm);
  const {
    formStructureMask,
    formStructureDetailHumidifier,
    formStructureHumidifier,
    formStructureNote,
    formStructureRampa,
    formStructureDetailRampa
  } = useParamsTharapyData(t, dataForm)

  useEffectOnlyUpdate(() => {
    if (!dataForm.interface) {
      const modeField = formStructureDetailRampa.map((ele) => ele.name).join(',').split(',')
      const copyDataForm = copyObject(dataForm)
      modeField?.forEach((key) => {
        delete copyDataForm[key]
      })
      setData(copyDataForm)
    }
  }, [dataForm.rampa]);


  useEffectOnlyUpdate(() => {
    if (!dataForm.humidifier) {
      const modeField = formStructureDetailHumidifier.map((ele) => ele.name).join(',').split(',')
      const copyDataForm = copyObject(dataForm)
      modeField?.forEach((key) => {
        delete copyDataForm[key]
      })
      setData(copyDataForm)
    }
  }, [dataForm.humidifier]);


  useEffect(() => {
    if (dataForm.mode) {
      const copyDataForm = copyObject(dataForm)
      delete copyDataForm?.pressure
      delete copyDataForm?.press_min
      delete copyDataForm?.press_max
      delete copyDataForm?.ipap_max
      delete copyDataForm?.ipap_min
      delete copyDataForm?.epap_max
      delete copyDataForm?.epap_min
      delete copyDataForm?.backupRate
      setData(copyDataForm)
    }
  }, [dataForm?.mode]);


  return (
    <>
      <div className={'d-flex'}>
        <div className={'w-100'}>
          <FormElements
            isSubmit={isSubmit}
            structure={formStructureMode}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValue}
          />
        </div>
        <div className={'w-50'}>
          <FormElements
            isSubmit={isSubmit}
            structure={formStructureDevice}
            dataForm={dataForm}
            errors={errors}
            changeValue={changeValue} />
        </div>
      </div>
      <p className={"title-paragraph"}>{t('sg.otherInfo')}</p>
      <FormElements
        structure={formStructureMask}
        dataForm={dataForm}
        errors={errors}
        changeValue={changeValue}
        isSubmit={isSubmit}
      />
      <FormElements
        isSubmit={isSubmit}
        structure={formStructureRampa}
        dataForm={dataForm}
        errors={errors}
        changeValue={changeValue}
      />
      <FormElements
        structure={formStructureHumidifier}
        dataForm={dataForm}
        errors={errors}
        changeValue={changeValue}
        isSubmit={isSubmit}
      />
      <FormElements
        structure={formStructureNote}
        dataForm={dataForm}
        changeValue={changeValue}
        errors={errors}
        isSubmit={isSubmit}
      />
    </>
  );
}
