export const templateNameType = {
  note: 'note',
  ossigeno: 'ossigeno',
  ventiloterapia: 'ventiloterapia',
  sleep: 'sleep',
  concentrator: 'concentrator',
  sleepStudy: 'sleepStudy',
  therapy: 'therapy',
  scoredDiagnosticStudy: 'scoredDiagnosticStudy',
  titration: 'titration',
  maskProduct: 'maskProduct',
  genericProduct: 'genericProduct',
  nivTherapy: 'nivTherapy',
}

export const templateNameId = {
  [templateNameType.note]: 'a175b9f6-dfce-45d5-8f84-c823331e18fc',
  [templateNameType.ossigeno]: 'a175b9f6-dfce-45d5-8f84-c823331e18fb',
  [templateNameType.ventiloterapia]: 'c175b9f6-dfce-45d5-8f84-c823331e18fa',
  [templateNameType.sleep]: '3e0ee5a5-7d91-4189-aca4-0f10e6a66f72',
  [templateNameType.concentrator]: 'eb5a65f8-92ba-4bbc-925a-e3abd90c8568',
  [templateNameType.sleepStudy]: '2b93a069-ee9f-4e9d-b84e-550da9dc2264',
  [templateNameType.therapy]: '5bdb4010-1ce5-4e73-9c05-54d686476510',
  [templateNameType.scoredDiagnosticStudy]: 'f93c2250-a525-499a-90c4-7c13f395d452',
  [templateNameType.titration]: '783fa3f6-29fc-48b5-9d2c-937489ef1e8b',
  [templateNameType.maskProduct]: 'eff4f8ce-1a67-43f0-b147-a5d5ad164911',
  [templateNameType.genericProduct]: 'c87673e4-e940-4449-9915-c9588baf77ab',
  [templateNameType.nivTherapy]: 'f12ec321-d1e2-41a9-8c56-da84048c8a76',
}
export const templateData = [
  {
    id: templateNameId[templateNameType.ventiloterapia],
    descrizione: 'Ventiloterapia Generico',
    name: 'Template003',
    template: templateNameType.ventiloterapia
  },
  {
    id: templateNameId[templateNameType.ossigeno],
    descrizione: 'Ossigeno terapia generico',
    name: 'Template001',
    template: templateNameType.ossigeno
  },
  {
    id: templateNameId[templateNameType.note],
    descrizione: 'note generico',
    name: 'Template002',
    template: templateNameType.note
  },
  {
    id: templateNameId[templateNameType.sleep],
    descrizione: 'sonno',
    name: 'Template004',
    template: templateNameType.sleep
  }
]
