import { modeSleepName, modeTherapyName } from "./modeData";
import { useModeDataTherapy } from "./useModeDataTherapy";

export const useModeTherapyData = (t: any, dataForm: any) => {
  const { formStructureModeChoose } = useModeDataTherapy(t);

  const formStructureMode = [
    {
      name: "mode",
      type: "select",
      label: `${t("prescription.template.mode.label")}*`,
      options: Object.keys(modeTherapyName).map(ele => ({
          label: t(`prescription.template.${ele}`),
          value: ele
        })
      ),
      dataElement: {
        type: "text"
      },
      col: { xs: "6", sm: "4", lg: "3", xl: "3" }
    },
    ...(dataForm?.mode && formStructureModeChoose?.[dataForm.mode]
      ? formStructureModeChoose[dataForm.mode]
      : []),
  ];

  return {
    formStructureMode,
    formStructureModeChoose
  };
};
