import {AddProductToCartAction} from "../cart/cartActions";
import {setEditOrderData, setEditProductOrderData, setProductOrderData} from "./orderSlice";


export const setProductOrderDataActionPharmacy = (t, data, callback) => (dispatch, getState) => {
    dispatch(
        AddProductToCartAction(t, data, true, () => {
            const cart = getState().cart.cart;
            dispatch(setOrderDataAction(cart));
            callback && callback()
        }),
    );
};

export const setProductOrderDataActionPrescriber = (t, data, callback) => (dispatch, getState) => {
    dispatch(
        AddProductToCartAction(t, data, true, () => {
            dispatch(setOrderDataAction([data]));
            callback && callback()
        }),
    );
};

export const setProductEditOrderDataActionPrescriber = (product, data = {}, callback) => (dispatch, getState) => {
    dispatch(setEditProductOrderData({products: [product], data}))
    callback && callback()
};
export const setOrderDataAction = (products, data) => (dispatch, getState) => {
    dispatch(setProductOrderData({products, data}));
};

export const setEditOrderDataAction = (order) => (dispatch, getState) => {
    dispatch(setEditOrderData({...order, products: order.productCart}));
};



