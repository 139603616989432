import React, {useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDisabledProduct,
    selectListProducts,
} from "../../../redux/products/productsSlice";
import ProductCard from "../../shared/ui/product/ProductCard";
import {selectPatient} from "../../../redux/patient/patientSlice";
import {selectIsPharmacy} from "../../../redux/customer/customerSlice";
import {useRoute} from "../../utils/useRoute";
import * as routeName from "../../routes/routeNameAuthType";
import {Col, Container, Row} from "react-bootstrap";
import {nameSteps} from "../../shared/app/wizard_order/wizardOrderData";
import WizardOrder from "../../shared/app/wizard_order/WizardOrder";
import TitleCard from "../../shared/app/titleCard/TitleCard";
import {UseTitleApp} from "../../core/useTitleApp";
import {pages} from "../../core/titleApp";
import {setFromCart} from "../../../redux/order/orderSlice";
import {useLanguageForShared} from "../../../shared/utils/languages/useLanguageForShared";

export function ProductsPage({history}) {
    const {t} = useLanguageForShared();

    const products = useSelector(selectListProducts);
    const patient = useSelector(selectPatient);
    const isPharmacy = useSelector(selectIsPharmacy);
    const disabledProducts = useSelector(selectDisabledProduct);
    const dispatch = useDispatch();
    const {changeRoute} = useRoute();
    useEffect(() => {
        dispatch(setFromCart(false));
        if (!(isPharmacy || patient)) {
            changeRoute(routeName.historical);
        }
    }, [isPharmacy]);
    const {label} = UseTitleApp(t, pages.products, isPharmacy);
    return (
        <Container>
            <Row>
                <Col xs="12">
                    <WizardOrder selectedStep={nameSteps.products}/>
                    <Card>
                        <CardHeader>
                            {/*{patient && <UserDetail customer={patient} />}*/}
                            <TitleCard title={label.title} subtitle={label.subtitle}/>
                        </CardHeader>
                        <CardBody>
                            <Row className="product-row" data-test="products-div">
                                {products.map((pr, key) => (
                                    <Col
                                        key={key}
                                        xs="12"
                                        md="6"
                                        className="product-col"
                                        data-test-quantity={!!pr.specifiedQuantity}
                                        data-test-price={!!pr.price}
                                        data-test="single-product-div"
                                    >
                                        <ProductCard
                                            disabled={disabledProducts.includes(pr.id)}
                                            key={pr.id}
                                            product={pr}
                                        ></ProductCard>
                                    </Col>
                                ))}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
