import React from "react";
import {FormElements} from "../../../../../../shared/bootstrap/form/FormElements";
import {useSleepStudyData} from "./data/useSleepStudyData";
import {useLanguageForShared} from "../../../../../../shared/utils/languages/useLanguageForShared";
import {useParamsData} from "./data/useParamsData";
import {UploadComponent} from "../UploadComponent";

export function TemplateScoredDiagnosticStudy({dataForm, changeValue, errors, setData, isSubmit}) {
    const {t} = useLanguageForShared()
    const {
        formStructureTest,
        formStructureTestExecution,
        formStructureTestRequest,
    } = useSleepStudyData(t, dataForm)
    const {formStructureNote} = useParamsData(t, dataForm)

    return (
        <>
            <FormElements
                isSubmit={isSubmit}
                structure={formStructureTest}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
            />
            <FormElements
                isSubmit={isSubmit}
                structure={formStructureTestExecution}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
            />
            <FormElements
                isSubmit={isSubmit}
                structure={formStructureTestRequest}
                dataForm={dataForm}
                errors={errors}
                changeValue={changeValue}
            />
            <UploadComponent
                setUploadFile={(file) => dataForm.file = file}
                setDescriptionFile={(value) => dataForm.description = value}
            />
            <FormElements
                structure={formStructureNote}
                dataForm={dataForm}
                changeValue={changeValue}
                errors={errors}
                isSubmit={isSubmit}
            />

        </>
    );
}
