export const partsData = {
  '1090297': "Amara Gel Headgear",
  '1090698-G': "Amara Gel Clips",
  '1090296': "Amara Gel Headgear - Reduced Size",
  '1090696': "Amara View Headgear - Large",
  '1090697': "Amara View Headgear - Standard",
  '1136626': "Amara View Clips - Magnetic",
  '1090698-V': "Amara View Clips",
  '1133450': "DreamWear Full Face Headgear",
  '1133454': "DreamWear Full Face Clips - Magnetic",
  '1116748': "DreamWear EE Elbow",
  '1116751': "DreamWear Headgear with arms",
  '1116750': "DreamWear Nasal Headgear",
  '1137964': "DreamWisp Headgear",
  '1137965': "DreamWisp Headgear - Large",
  '1120434': "DreamWisp Clips - Magnetic",
  '1137966': "DreamWisp Headgear - Reduced Size",
  '1060807': "FitLife Headgear S",
  '1060808': "FitLife Headgear L",
  '1089990': "FitLife Headgear XL",
  '1060809': "FitLife Elbow",
  '7041': "Mask Swivel",
  '1060913': "FitLife Headgear Clips",
  '1105176': "Nuance Headgear",
  '1105178': "Nuance Pro Headgear",
  '1094082': "Wisp Headgear Standard",
  '1094078': "Wisp Headgear L",
  '1109307': "Wisp Headgear Reduced Size",
  '1120434-W': "Wisp Magnetic Headgear Clips",
  '1094083': "Wisp Headgear Clips",
  '1144267': "Dreamstation 80w Power Supply",
  '1039026': "Dreamstation Power Cord UK Plug",
  '1006085': "Deluxe Chin Strap",
  '1012911': "Premium Chin Strap",
  '302418': "Pressure Valve",
  '312710': "O2 Enrichment Attachment",
  '1121162': "Dreamstation Carry Case",
  '1124465': "Dreamstation Travel Case",
  '1126942': "Lithium ION Battery PAP",
  'R1124009': "Rental Lithium ION Battery PAP",
  '1126944': "Lithium ION Battery Respiratory",
  '1142834': "Dreamstation Carry Case",
  '1142982': "A Series Power Supply",
  '300DBM': "A40 Pro Detachable Battery Module"
};