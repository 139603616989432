import React from "react";
import {Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {templateRdoType} from "../../../../../redux/order/orderData";
import {DetailDelivery} from "../DetailDelivery";
import {DetailOrderRequest} from "../DetailOrderRequest";
import RowTag from "./RowTag";
import {resetModal, setModal} from "../../../../shared/redux/modal/modalSlice";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import {selectCustomerType} from "../../../../modules/Auth/_redux/authRedux";
import {customerTypeName} from "../../../../../redux/customer/customerData";
import DetailPatientRegistryMore from "./DetailPatientRegistryMore";
import OrderRow from "../../../historical/components/OrderRow";
import ActionHeader from "../../../historical/components/ActionHeader";
import LastUpdateInline from "../../../historical/components/LastUpdateInline";
import AlertRequestDelete from "../alert/AlertRequestDelete";
import {copyObject} from "../../../../utils/objUtils";

export function HeaderDetailOrder({
                                      orderDetail,
                                      order,
                                      changeStateOrder,
                                      updateOrderHandler,
                                      setOrderInEdit,
                                      refComp,
                                      closeDetailHandler,
                                      noActions
                                  }) {
    const dispatch = useDispatch();
    const {t} = useLanguageForShared();
    const customerTypeSel = useSelector(selectCustomerType);

    const buttonList = [{
        label: t("action.cancel"), action: "cancelActionModal", type: "secondary", dataType: {}
    }, {
        label: t("action.confirm"), action: "confirmActionModal", type: "primary", dataType: {}
    }];
    const cancelActionModal = () => {
        dispatch(resetModal());
    };
    const confirmActionModal = (ele) => {
        dispatch(resetModal());
        const copyEle = copyObject(ele);
        delete copyEle.msg;
        changeStateOrder && changeStateOrder({rdoId: order.codiceRDO, ...copyEle});
    };

    const changeState = (ele) => {
        if (ele.msg) {
            dispatch(setModal(buttonList, {
                title: ele.msg.title, body: ele.msg.body
            }, {
                cancelActionModal, confirmActionModal: () => confirmActionModal(ele)
            }));
        } else {
            changeStateOrder && changeStateOrder({rdoId: order.codiceRDO, actionNotes: "note", ...ele});
        }
    };

    const patient = orderDetail?.patient;
    const lastModified = order?.cronologyState ? order?.cronologyState[order?.cronologyState?.length - 1] : undefined;

    return (
        <Row className="headerDetailOrder">
            <Col xs="12">
                <Row className={"row-detail-order"}>
                    <Col xs="12">
                        <div
                            className={"title-page header-detail d-flex justify-content-between align-items-baseline flex-wrap"}>
                <span className={"d-flex gap-5"}><h3>{t("sg.form.requestDetail")}</h3>
                    {customerTypeSel === customerTypeName.bo && <LastUpdateInline lastModified={lastModified}/>}
              </span>
                            {!noActions && <ActionHeader order={order} changeState={changeState} refComp={refComp}
                                                         closeDetailHandler={closeDetailHandler}/>}
                        </div>


                        {order?.latestAction && order?.latestAction?.azione?.name === "AZ-Richiedicancellazione" &&
                            <AlertRequestDelete
                                user={`${order?.latestAction?.azioneCognomeUtente} ${order?.latestAction?.azioneNomeUtente}`}/>}

                        <Row className={"order-row"}>
                            {order.rdOLabel && (<>
                                <Col className={"order-detail"}>
                                    <OrderRow order={order}/>
                                    {/*da spostare questa parte*/}
                                    {/* <Col xs="12">
                    <RowTag
                      setOrderInEdit={setOrderInEdit}
                      paramValidation={order?.orderCodeType?.type}
                      isOnlyRead={!order.orderCodeType}
                      listNR={order?.ordersCodes || []}
                      idOrder={order.codiceRDO}
                      updateOrderHandler={updateOrderHandler}
                      providerList={order?.availableProviders}
                    />
                  </Col>*/}
                                    {/*end*/}
                                </Col>
                            </>)}
                        </Row>

                    </Col>
                    <Col xs="12" md="12">
                        {order.orderRequest.templateRDO !== templateRdoType.GENFAR && <div className={"row"}>
                            <div className={"col-12 col-xl-12"}>
                                <DetailOrderRequest order={order} orderDetail={orderDetail}/>
                            </div>
                        </div>}

                        {patient && <section>
                            <h3 className={"title-section"}>{t("sg.section.patient")}</h3>
                            <DetailPatientRegistryMore patient={patient} order={order}/>
                        </section>}

                    </Col>

                </Row>

                <Row className={"row-gap-2"}>
                    {order.orderRequest.templateRDO === templateRdoType.GENFAR && (<Col xs="12" className={"mt-4"}>
                        <DetailDelivery
                            setOrderInEdit={setOrderInEdit}
                            orderDetail={orderDetail}
                            order={order}
                            updateDateHandler={updateOrderHandler}
                        />
                    </Col>)}
                </Row>
            </Col>
        </Row>
    );
}
