import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    actionPerform,
    changeOrderAction,
    changeOrderState,
    getOrder
} from "../../../../../redux/order/orderCrud";
import {OrderDetailComponent} from "./OrderDetailComponent";
import {clearOrder} from "../../../../utils/ordersUtils";
import {useDispatch, useSelector} from "react-redux";
import {
    setErrorTypeAction,
    setSuccessErrorAction
} from "../../../../../redux/errors/errorsActions";
import {useLanguageForShared} from "../../../../../shared/utils/languages/useLanguageForShared";
import {Card, Col, Row} from "react-bootstrap";
import {CardBody} from "../../../../../_metronic/_partials/controls";
import WelcomeComponent from "../../../historical/components/WelcomeComponent";
import PanelBottom from "../../../../../shared/custom/panelBottom/PanelBottom";
import PanelActions from "../../../historical/components/PanelActions";
import {actionsNewObj} from "../../../../utils/actionData";
import {customerTypeName} from "../../../../../redux/customer/customerData";
import {selectCustomerType} from "../../../../modules/Auth/_redux/authRedux";
import EmptyData from "../../../../../shared/custom/empty/EmptyData";

export function OrderDetailLoad({
                                    idOrder,
                                    reloadOrder,
                                    setOrderInEdit,
                                    suspensionCallback,
                                    closeDetailHandler
                                }) {
    const {t} = useLanguageForShared();
    const customerTypeSel = useSelector(selectCustomerType);

    const [order, setOrder] = useState();
    const dispatch = useDispatch();

    const [errorOrderDetail, setErrorOrderDetail] = useState(undefined);
    const loadOrderCall = () => {
        setErrorOrderDetail(false);
        getOrder(idOrder)
            .then((res) => {
                setOrderData(res);
                reloadOrder && reloadOrder(res);
            })
            .catch((error) => {
                setErrorOrderDetail(true);
                dispatch(setErrorTypeAction(t("sg.msg.errorSearchOrder")));
            });
    };

    const setOrderData = (res) => {
        setOrder(clearOrder(res));
        // setOrderHandler(res);
    };
    useEffect(() => {
        setOrder(undefined);
        idOrder && loadOrderCall();
    }, [idOrder]);

    const changeStateOrder = (data) => {
        actionPerform(data)
            .then((res) => {
                // setOrderData(res);
                loadOrderCall();
                // reloadOrder && reloadOrder(res);
                dispatch(setSuccessErrorAction(t("sg.msg.uploadStateSuccess")));

            })
            .catch((error) => {
                dispatch(setErrorTypeAction(t("sg.msg.errorLoad")));
            });
        // changeOrderAction(data)
        // 	.then((res) => {
        // 		setOrderData(res);
        // 		reloadOrder && reloadOrder(res);
        // 		dispatch(setSuccessErrorAction(t('sg.msg.uploadStateSuccess')));
        //
        // 	})
        // 	.catch((error) => {
        // 		dispatch(setErrorTypeAction(t('sg.msg.errorLoad')));
        // 	});
    };

    const initHeightContentDetail = `${customerTypeSel === customerTypeName.prescriber ? "calc(100vh - 75px)" : "calc(100vh - 75px)"}`;
    const [heightContentDetail, setHeightContentDetail] = useState(initHeightContentDetail);

    const [showPanelBottom, setShowPanelBottom] = useState(undefined);
    const panelBottom = useRef(null);
    const suspensionCallbackHandler = (data) => {
        setOrderData(data[0]);
        suspensionCallback && suspensionCallback(data[0])
    }
    useEffect(() => {
        setShowPanelBottom(order?.currentStateCode === "Final.Aperta.Inlavorazione");
    }, [order]);


    useLayoutEffect(() => {
        if (panelBottom && panelBottom?.current) {
            const heightPanel = panelBottom?.current?.offsetHeight;
            setHeightContentDetail(`calc(100vh - ${heightPanel + 50}px)`);
        } else {
            setHeightContentDetail(initHeightContentDetail);
        }
    }, [showPanelBottom, panelBottom]);

    const haveActions = order?.actions?.includes(actionsNewObj["AZ-Chiudilav"]) ||
        order?.actions?.includes(actionsNewObj["AZ-Approva"]) ||
        order?.actions?.includes(actionsNewObj["AZ-Cancella.cliente"]) ||
        order?.actions?.includes(actionsNewObj["AZ-Cancella.BO"]);

    return (
        <div className="ps-0 col-history-item">
            <Card>
                <CardBody style={{height: heightContentDetail, overflow: "auto"}}>
                    <Row className="h-100">
                        <Col xs="12">
                            {errorOrderDetail ? <EmptyData title={t("sg.msg.emptyMsgDetail")}></EmptyData> :
                                idOrder ? (
                                    order && <OrderDetailComponent
                                        suspensionCallback={suspensionCallbackHandler}
                                        setOrderInEdit={setOrderInEdit}
                                        order={order}
                                        orderDetail={order.orderDetail}
                                        changeStateOrder={changeStateOrder}
                                        updateOrderHandler={(order) => setOrderData(order)}
                                        closeDetailHandler={closeDetailHandler}
                                    />
                                ) : (
                                    <WelcomeComponent/>
                                )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {idOrder && showPanelBottom && haveActions && <PanelBottom ref={panelBottom}>
                <PanelActions
                    closePanel={() => changeStateOrder({
                        rdoId: order.codiceRDO, action: {
                            Name: "AZ-AnnullaPresaincarico"
                        }
                    })}
                    order={order}
                    actionPanel={(data) => changeStateOrder({
                        rdoId: order.codiceRDO,
                        ...data
                    })}
                    haveActions={haveActions}/>
            </PanelBottom>}
        </div>
    );
}
