import React, {useRef} from 'react'
import {classByArray} from '../../../functions/function'
import TimeInputCustom from '../timeInput/TimeInputCustom'
import InputCustomDate from "../dateInput/InputCustomDate";
import {InputCustomPropsModel} from "../models/InputCustomPropsModel";
import Checkbox from "../checkbox/Checkbox";


const InputCustom = ({
                         changeValue,
                         type,
                         name,
                         error,
                         value,
                         extra = {},
                         ...rest
                     }: InputCustomPropsModel) => {
    const changeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (changeValue) {
            changeValue(
                type === 'checkbox'
                    ? event.target.checked
                    : type === 'number'
                        ? +event.target.value
                        : event.target.value,
                event
            )
        }
    }
    const changeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeValue(event.target.checked ? true : false, event);
    };
    const classArray = ['sh form-control', ...(error ? ['error'] : [])]
    const classString = classByArray(classArray);
    const myRef = useRef<any>();
    return (
        <>
            {type === 'text' && (
                <input
                    className={classByArray(classArray)}
                    name={name}
                    value={value}
                    onChange={changeInput}
                    data-test={'input-' + name}
                    {...rest}
                />
            )}
          {type === 'number' && (
                <input
                    className={classByArray(classArray)}
                    name={name}
                    value={value}
                    onChange={changeInput}
                    data-test={'input-' + name}
                    {...rest}
                />
            )}
            {type === 'checkbox' && (
                <Checkbox
                    checked={value}
                    name={name}
                    value={value}
                    onChange={changeInput}
                    data-test={'check-' + name}
                    {...rest}
                />
            )}
            {type === 'date' && (
                <InputCustomDate
                    error={error}
                    name={name}
                    value={value}
                    extra={extra}
                    type={type}
                    changeValue={changeValue}
                    classString={classString}
                    data-test={'date-' + name}
                    {...rest}
                />
            )}
            {type === 'time' && (
                <TimeInputCustom
                    error={error}
                    name={name}
                    value={value}
                    changeValue={changeValue}
                    data-test={'time-' + name}
                    {...rest}
                ></TimeInputCustom>
            )}
        </>
    )
}

export default InputCustom
