import React from "react";
import {Col, Row} from "react-bootstrap";
import {ProductCart} from "../../../cart/ProductCart";
import {TemplateSelectProduct} from "./templates/TemplateSelectProduct";


export function SelectProductForm(
    {
        product, setData, changeQuantityProduct,
        dataForm, changeValue, errors, isSubmit
    }) {
    return (
        <Row>
            {product && (
                <>
                    <Col xs="12" className="mb-5">
                        <ProductCart
                            changeValue={() => {
                            }}
                            changeQuantity={(quantity) =>
                                changeQuantityProduct && changeQuantityProduct(quantity)
                            }
                            product={product}
                        />
                    </Col>
                    <Col xs="12">
                        <TemplateSelectProduct
                            isSubmit={isSubmit}
                            dataForm={dataForm}
                            template={product.template}
                            setData={setData}
                            changeValue={changeValue}
                            errors={errors}
                        />
                    </Col>
                </>
            )}
        </Row>
    );
}
