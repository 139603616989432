export const devicesMapped = {
  "GBX520H15C": "Dreamstation 2 Auto CPAP Advanced",
  "RGBX520H15C": "Rental Dreamstation 2 Auto CPAP Advanced",
  "GBX500S15": "Dreamstation Auto CPAP",
  "RGBX500S15": "Rental Dreamstation Auto CPAP",
  "RIN561S": "REMstar Auto CPAP (System 1 60)",
  "GBX700S15": "Dreamstation Auto BiPAP",
  "RGBX700S15": "Rental Dreamstation Auto BiPAP",
  "RIN761S": "BiPAP Auto (System 1 60)",
  "GBX400S15": "Dreamstation CPAP Pro",
  "PWX401S15": "Dreamstation CPAP Pro with AutoTrial",
  "GBX3100S19": "BiPAP A40 Pro",
  "RGBX3100S19": "Rental BiPAP A40 Pro",
  "GBX1030S20": "Dreamstation BiPAP ST 30",
  "RGBX1030S20": "Rental Dreamstation BiPAP ST 30",
  "GBX900S15": "Dreamstation BiPAP autoSV",
  "RGBX900S15": "Rental Dreamstation BiPAP autoSV",
  "RIN961S": "BiPAP autoSV (System 1 60)",
  "INX1130S19": "Dreamstation BiPAP AVAPS 30 with AAM",
  "1054096B": "Trilogy 100",
  "GB2110X15B": "Trilogy EVO",
  "IN2100X15B": "Rental Trilogy EVO",
  "GBXH": "Dreamstation Humidifier",
  "RGBXH": "Rental Dreamstation Humidifier",
  "GBX3H": "A40 Humidifier",
  "RGBX3H": "Rental A40 Humidifier",
  "RIN6H": "System 1 60 Humidifier"
}
