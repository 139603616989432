import React from "react";
import {Col, Row} from "react-bootstrap";
import {TotalDelivery} from "./TotalDelivery";
import {TotalOrder} from "./TotalOrder";
import {TotalProduct} from "./TotalProduct";

export function RowTotal({products, orderDetail}) {
    const deliveryPrice = orderDetail?.deliveryData?.price || 0;
    const deliveryQta = products?.reduce((acc, ele) => {
        return acc + ele.quantity;
    }, 0);
    const specifiedQuantity = orderDetail?.deliveryData?.specifiedQuantity; //TODO controllare proprietà
    const totalProducts = products?.reduce((acc, ele) => {
        return acc + ele.price * (ele.quantity || 1);
    }, 0);
    const totalOrder =
        totalProducts + deliveryPrice * (specifiedQuantity ? deliveryQta : 1);

    return (
        <Row className="item-total justify-content-end  align-items-end py-5">
            <Col xs="auto text-right me-5">
                {orderDetail?.deliveryData && deliveryPrice > 0 && (
                    <>
                        <TotalDelivery
                            price={deliveryPrice}
                            qta={deliveryQta}
                            specifiedQuantity={specifiedQuantity}
                        />
                        {products.length > 1 && <TotalProduct total={totalProducts}/>}
                    </>
                )}
            </Col>
            {(products.length > 1 || deliveryPrice > 0) && (
                <Col xs="auto text-right ms-5">
                    <TotalOrder total={totalOrder}/>
                </Col>
            )}
        </Row>
    );
}
